<template>
  <div ref="events" class="events">
    <div class="section-header">
      <h3>Events</h3>
    </div>
    <v-data-table
      :headers="headers"
      :items="rows"
      :items-per-page="1000"
      hide-default-footer
      disable-pagination
      disable-filtering
      disable-sort
      mobile-breakpoint="0"
      density="compact"
      :height="tableHeight"
    >
      <template #bottom></template>
      <template #footer>
        <em style="margin: 0 16px; font-size: 10px">Datetime as user local time</em>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { parse, format, isSameDay, addDays, startOfToday } from "date-fns"

export default {
  inject: ["apiRepo", "sovereignUniverse"],
  props: {
    region: { type: String },
    autoHeight: { type: Boolean, default: true }
  },
  data() {
    return {
      isSameDay,
      today: format(new Date(), "yyyy-MM-dd"),
      rows: []
    }
  },
  computed: {
    tableHeight() {
      if (this.autoHeight) {
        return "auto"
      }
      switch (this.$vuetify.display.name) {
        case "xs":
        case "sm":
          return "auto"
        case "md":
          return 296
        default:
          return 370
      }
    },
    headers() {
      return this.rows.length > 0
        ? [
            { title: "DateTime", width: 120, key: "dateTime" },
            { title: "Region", width: 80, key: "region" },
            { title: "Event", width: 220, key: "eventName" },
            { title: "Survey", width: 70, key: "survey" },
            { title: "Prior", width: 70, key: "prior" }
          ]
        : []
    }
  },
  async mounted() {
    const events = await this.apiRepo.requestBlobCSV("econ-releases/econ-releases.csv")
    this.$watch(
      "region",
      () => {
        this.rows = this.filterEvents(events).map((event) => {
          return {
            dateTime: event["Date Time"],
            eventName: (event.Period ? `[${event.Period}] ` : "") + event.Event,
            survey: event["Surv(M)"],
            prior: event.Prior,
            region: event["Country/Region"]
          }
        })
      },
      { immediate: true }
    )
    // this.$nextTick(() => {
    //   var tableWrapper = this.$refs.events.querySelector(".v-data-table__wrapper")
    //   new Ps(tableWrapper)
    // })
  },
  methods: {
    getName(code) {
      return this.sovereignUniverse.fromAlpha2(code)?.name
    },
    filterEvents(events) {
      const result = events
        .filter((d) => d["Date Time"] && d["Event"])
        .filter((d) => (!this.region ? true : d["Country/Region"] === this.getName(this.region)))
        .map((d) => {
          const date = d["Date Time"]
          const start = date.includes(":")
            ? parse(date + "-0500", "MM/dd/yy HH:mmxxxx", new Date()) // as being said, NTC is the center of universe
            : parse(date.split(/[-]/)[0], "MM/dd/yy", new Date())
          const end = date.includes("-") ? parse(date.split(/[-]/)[1], "MM/dd/yy", new Date()) : start
          return { ...d, start, end, name: d["Event"], regionName: d["Country/Region"] }
        })
        .filter((d) => d.end >= startOfToday() && d.end <= addDays(startOfToday(), 1))
        .sort((a, b) => a.start - b.start)

      return result
    },
    formatDateTime(date) {
      const output = format(date, "MMM dd hh:mm b")
      if (output.includes("12:00")) return format(date, "MMM dd")
      else return output
    }
  }
}
</script>

<style>
.events {
  position: relative;
  width: auto;
}
.events table {
  table-layout: fixed;
}

.v-theme--dark .today-item {
  background: #1e1d1d;
  color: #229e45;
}
.v-theme--light .today-item {
  background: #e1ecf4;
  color: #1c1c1c;
}
</style>
