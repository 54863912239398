<template>
  <v-card class="position-card" style="height: 100%">
    <v-card-title class="text-body-2 text-uppercase font-weight-medium py-1 d-flex">
      <div style="height: 32px; line-height: 32px">{{ cardTitle }}</div>
      <v-spacer></v-spacer>
      <v-btn v-if="to" size="small" :to="to" icon class="mx-0">
        <v-icon>mdi-link</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <div class="d-flex justify-space-between flex-wrap">
        <template v-if="Object.keys(items).length > 0">
          <div v-for="(value, title) in items" :key="title">
            <div style="font-size: 24px; line-height: 32px">
              {{ value }}
            </div>
            <div class="text-caption">{{ title }}</div>
          </div>
        </template>
        <div v-else class="mt-3 text-caption">No {{ cardTitle.toLowerCase() }} position</div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    to: { type: String, default: null },
    cardTitle: { type: String, default: null },
    items: { type: Object, default: () => ({}) }
  }
}
</script>
