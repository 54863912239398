import { h, type App } from "vue"
import PerfectScrollbarLib from "perfect-scrollbar"
import PerfectScrollbar from "vue3-perfect-scrollbar"
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css"

let exportPs: {
  //eslint-disable-next-line  @typescript-eslint/no-explicit-any
  install: (app: App) => any
}

const ua = navigator.userAgent
const isTouchDevice =
  "ontouchstart" in document.documentElement || ua.includes("iPhone") || ua.includes("Macintosh") || ua.includes("iPad")

if (!isTouchDevice) {
  exportPs = PerfectScrollbar
} else {
  exportPs = {
    install(app) {
      app.component("PerfectScrollbar", {
        name: "PerfectScrollbar",
        render() {
          return h(
            "div",
            {
              class: "native-touch-scroll-container",
              style: { overflow: "auto" }
            },
            this.$slots.default
          )
        }
      })
    }
  }
}

//eslint-disable-next-line  @typescript-eslint/no-empty-function
const Ps = isTouchDevice ? function () {} : PerfectScrollbarLib

export { Ps, isTouchDevice }
export default exportPs
