<template>
  <div>
    <div class="section-header">
      <h3>{{ title }}</h3>
      <div class="text-overline mx-4">
        <ColorScaledScore :score="fmtNum(totalScore, 0)" />
      </div>

      <v-alert v-if="message" class="alert-inset mx-2 mb-0" text dense :type="alertType">
        <span style="font-size: 12px">
          {{ message }}
        </span>
      </v-alert>

      <div v-if="!expandedView" class="section-header-right ml-sm-0 ml-md-auto">
        <v-btn-toggle v-model="viewType" mandatory rounded density="compact">
          <v-btn size="x-small">Scores</v-btn>
          <v-btn size="x-small">Comments</v-btn>
        </v-btn-toggle>
      </div>
    </div>

    <div class="content-card" :style="{ height: $vuetify.display.xs ? 'auto' : '360px' }">
      <div v-if="expandedView || viewType === 0">
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn v-if="!isEditing" size="x-small" variant="text" color="primary" @click="isEditing = true">Edit</v-btn>
          <template v-else>
            <v-btn size="x-small" variant="text" color="warning" @click="discardLocalAll">Discard</v-btn>
            <v-btn size="x-small" variant="outlined" color="primary" @click="updateBatch">Save</v-btn>
          </template>
        </div>
        <div
          v-for="({ label, score, newFactor, deprecated }, index) in factors"
          :key="index"
          class="d-flex align-center"
          :class="{ deprecated }"
        >
          <v-icon v-if="newFactor" class="mr-1" size="small" color="green">mdi-new-box</v-icon>
          <div class="text-caption" style="flex: 1" v-text="label"></div>
          <v-slider
            :disabled="!isEditing || deprecated"
            step="5"
            style="flex: 1"
            :model-value="score"
            hide-details
            @update:model-value="updateLocal(index, $event)"
          ></v-slider>
          <span class="text-overline" style="width: 24px; text-align: right">{{ score }}</span>
        </div>
      </div>

      <h4 v-if="expandedView" class="section-title-incard">
        <v-icon size="small">mdi-file-document-outline</v-icon>
        Comments
      </h4>
      <div v-if="expandedView || viewType === 1">
        <div style="height: 336px">
          <DocContentEditor :resource-path="`/api/corp_crm_models/${issuer}`" :remote-key="`${remoteKey}.comments`" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { differenceBy, pick, cloneDeep } from "lodash-es"
import DocContentEditor from "@/components/common/DocContentEditor.vue"
import EventBus from "@/utils/eventbus.js"
import ColorScaledScore from "./ColorScaledScore.vue"
import { fmtNum } from "@/utils/vmMethods.js"

export default {
  components: { DocContentEditor, ColorScaledScore },
  inject: ["apiRepo"],

  props: {
    issuer: { type: String, required: true },
    title: { type: String, required: true },
    remoteKey: { type: String, required: true },
    modelData: { type: Object },
    template: { type: Object },
    dataLoaded: { type: Boolean, default: false },
    expandedView: { type: Boolean, default: false }
  },

  data() {
    return {
      viewType: 0,
      factors: [],
      existingFactors: [],
      ready: false,
      isEditing: false,
      message: "",
      alertType: "info"
    }
  },

  computed: {
    totalScore() {
      const filtered = this.factors.filter((d) => !d.deprecated)
      const sumed = filtered.reduce((iter, cur) => iter + cur.score, 0)
      return sumed / filtered.length
    }
  },

  async mounted() {
    this.$watch(
      "dataLoaded",
      (v) => {
        if (!v) return

        this.ready = v

        const { sub_factors: existingFactors, comments } = this.modelData[this.remoteKey]
        this.existingFactors = existingFactors //keep the original model data untouched in the model
        this.factors = cloneDeep(this.existingFactors)
        this.comments = comments

        if (!this.modelData.upgraded) {
          this.factors = this.template[this.remoteKey].sub_factors
          //copy exisiting value back if already have value
          for (let i = 0; i < this.factors.length; i++) {
            const factor = this.factors[i]
            const existing = existingFactors.find((f) => f.label === factor.label)
            if (existing) {
              factor.score = existing.score
            } else {
              this.factors[i].newFactor = true
            }
          }
        }

        const deprecated = differenceBy(this.existingFactors, this.factors, "label").map((d) => ({
          ...d,
          deprecated: true
        }))
        this.factors = [...this.factors, ...deprecated]
      },
      { immediate: true }
    )
  },

  methods: {
    fmtNum,
    updateLocal(index, value) {
      this.factors[index].score = value
    },

    discardLocalAll() {
      this.factors = cloneDeep(this.existingFactors)
      this.isEditing = false
    },

    async updateBatch() {
      //push factor score to backend
      if (!Array.isArray(this.factors) || this.factors.length === 0) {
        this.message = "factors are not organized as desired format"
        this.alertType = "error"
        return
      }

      const api = await this.apiRepo.makeServiceAPIClient()
      const { status } = await api.patch(`/api/corp_crm_models/${this.issuer}`, {
        path: `${this.remoteKey}.sub_factors`,
        value: this.factors
          .filter((d) => d.label !== "Price/volume competition123")
          .filter((d) => !d.deprecated)
          .map((d) => pick(d, ["label", "score"])),
        update_crm_date: true
      })

      if (status === 200) {
        this.isEditing = false
        //update existingFactor since we are not plan to reload the data from remote here
        this.existingFactors = cloneDeep(this.factors)
        EventBus.$emit("corp-risk-model-updated")
      } else {
        this.message = "failed to save the score change, please retry"
        this.alertType = "error"
      }
    }
  }
}
</script>

<style lang="scss">
.deprecated {
  opacity: 0.5;
  .caption {
    text-decoration: line-through;
  }
}
</style>
