<template>
  <div>
    <div class="section-header">
      <h3>Issuer Description</h3>
    </div>
    <div class="content-card" :style="{ height: $vuetify.display.xs ? 'auto' : '360px' }">
      <DocContentEditor
        :resource-path="`/api/corp_crm_models/${issuer}`"
        remote-key="issuer_description"
      ></DocContentEditor>
    </div>
  </div>
</template>

<script>
import DocContentEditor from "@/components/common/DocContentEditor.vue"

export default {
  components: {
    DocContentEditor
  },
  props: {
    issuer: { type: String, required: true },
    expandedView: { type: Boolean, default: false }
  }
}
</script>
