<template>
  <div class="section-grid" :style="gridStyle">
    <div>
      <FactorScoreRollUp
        :data-loaded="dataLoaded"
        title="Business Profile"
        remote-key="business_profile"
        :issuer="issuer"
        :model-data="modelData"
        :template="templateData"
      ></FactorScoreRollUp>
    </div>
    <div>
      <CorpFinancials :issuer="issuer"></CorpFinancials>
    </div>
    <div>
      <FactorScoreRollUp
        :data-loaded="dataLoaded"
        title="Management Strategy"
        remote-key="mgmt_strategy"
        :issuer="issuer"
        :model-data="modelData"
        :template="templateData"
      ></FactorScoreRollUp>
    </div>
    <div>
      <FactorScoreRollUp
        :data-loaded="dataLoaded"
        title="Sovereign"
        remote-key="sovereign"
        :issuer="issuer"
        :model-data="modelData"
        :template="templateData"
      ></FactorScoreRollUp>
    </div>
    <div>
      <CorpDebtStructure :issuer="issuer"></CorpDebtStructure>
    </div>
    <div>
      <CorpDebtStructureFunding :issuer="issuer"></CorpDebtStructureFunding>
    </div>
  </div>
</template>

<script>
import FactorScoreRollUp from "@/components/common/FactorScoreRollUp.vue"
import CorpFinancials from "@/components/CorpFinancials.vue"
import CorpDebtStructure from "@/components/CorpDebtStructure.vue"
import CorpDebtStructureFunding from "@/components/CorpDebtStructureFunding.vue"

export default {
  components: {
    FactorScoreRollUp,
    CorpFinancials,
    CorpDebtStructure,
    CorpDebtStructureFunding
  },
  inject: ["apiRepo"],
  props: {
    issuer: { type: String, required: true }
  },
  data() {
    return {
      modelData: null,
      summaryData: null,
      templateData: null,
      dataLoaded: false
    }
  },
  computed: {
    gridStyle() {
      const cols = this.$vuetify.display.smAndDown ? 1 : this.$vuetify.display.lgAndUp ? 3 : 2
      return {
        display: "grid",
        "grid-template-columns": `repeat(${cols}, minmax(0, 1fr))`,
        // "align-content": "start",
        "column-gap": "8px",
        "grid-auto-flow": "dense",
        "max-width": "1880px"
      }
    }
  },
  async mounted() {
    const api = await this.apiRepo.makeServiceAPIClient()

    const [summaryData, modelData] = await Promise.all([
      api.get(`/api/corp_issuers/${this.issuer}`), //TODO: this shall be loaded with vuex to avoid multiple requests
      api.get(`/api/corp_crm_models/${this.issuer}`)
    ]).then(([resp1, resp2]) => [resp1.data, resp2.data])

    const { data: templateData } = await api.get(`/api/corp_crm_template?is_financial=${summaryData.is_financial}`)
    this.modelData = modelData
    this.summaryData = summaryData
    this.templateData = templateData
    this.dataLoaded = true
  }
}
</script>
