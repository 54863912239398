<template>
  <v-container fluid class="px-0">
    <v-row v-show="!maximized">
      <v-col cols="12" sm="12" md="6" lg="4" class="py-0">
        <CorpOpportunitySet
          v-if="!maximized"
          :maximized="maximized"
          :issuer="issuer"
          @toggle-maximized="maximized = !maximized"
        ></CorpOpportunitySet>
        <CorpHistoricalSpread :issuer="issuer"></CorpHistoricalSpread>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="8" class="py-0">
        <ValuationTool
          v-if="region"
          :issuer="issuer"
          :region="region"
          :show-all-corp-bonds="true"
          :show-regression="false"
          :show-u-s-swap-curve="false"
        ></ValuationTool>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" sm="12" md="12" lg="12" class="py-0">
        <CorpOpportunitySet
          v-if="maximized"
          :maximized="maximized"
          :issuer="issuer"
          @toggle-maximized="maximized = !maximized"
        ></CorpOpportunitySet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, inject, onMounted } from "vue"
import CorpOpportunitySet from "@/components/CorpOpportunitySet.vue"
import CorpHistoricalSpread from "@/components/CorpHistoricalSpread.vue"
import ValuationTool from "@/components/common/ValuationTool.vue"
import { loadIssuerInfo } from "@/apis/corp.js"

const apiRepo = inject("apiRepo")
const sovereignUniverse = inject("sovereignUniverse")

const props = defineProps({
  issuer: { type: String, required: true },
  showAllCorpBonds: { type: Boolean, default: false },
  showUSSwapCurve: { type: Boolean, default: true },
  cols: { type: Number, default: 1 }
})
const region = ref(null)
const maximized = ref(false)

onMounted(async () => {
  const info = await loadIssuerInfo(apiRepo, props.issuer)
  const entry = sovereignUniverse.fromAlpha3(info.country)
  region.value = entry.isoAlpha2
})
</script>

<style></style>
