/**
 * give a list of treasure yield curve years e.g.: [1 ,3, 5, 10], choose the
 * closest yield curve year to current bond's mature year. When there is a tie
 * number of years for current bond's year to mature in the array, pick the one
 * on the left side (more closer to present year)
 * e.g.: present year is 2023, for a bond mature year is 2026
 * given 1 year yield curve tsy(2024) and 6yr(2029) available, pick 1yr.
 * given 1 year yield curve tsy(2024) and 5yr(2028) available, pick 1yr.(tie)
 * given 3 year yield curve tsy(2026) and 6yr(2029) available, pick 3yr.
 * given 1 year yield curve tsy(2024) and 3yr(2026) available, pick 3yr.
 * @param {number[]} treasureYieldCurveYears
 * @param {number} currentBondMatureYear
 */
export default function (
  treasureYieldCurveYears: number[],
  currentBondMatureYear: number,
  presentYear = new Date().getFullYear()
) {
  if (currentBondMatureYear < presentYear) throw new Error("bond mature year is in the past")
  if (treasureYieldCurveYears.length === 0) throw new Error("no treasure yield curve exisits")
  if (treasureYieldCurveYears.length === 1) return treasureYieldCurveYears[0]

  const numOfYearBeforeBondMature = currentBondMatureYear - presentYear

  // yes, we don't quite want to mutate the original array pass in as parameter via javascript in place sort
  const sortedTreasureYieldCurveYears = treasureYieldCurveYears.slice(0).sort((a, b) => a - b)

  // e.g.[3,2,2,3]
  const bondMatureYearDiffs = sortedTreasureYieldCurveYears.map((year) => Math.abs(year - numOfYearBeforeBondMature))

  //get the first 2 from the previous Array [3,2,2,3]
  for (let i = 0; i < bondMatureYearDiffs.length - 1; i++) {
    //skip desending
    if (bondMatureYearDiffs[i] > bondMatureYearDiffs[i + 1]) {
      continue
    }

    if (bondMatureYearDiffs[i] === bondMatureYearDiffs[i + 1]) {
      return sortedTreasureYieldCurveYears[i]
    }

    //ascending, pick the left one
    if (bondMatureYearDiffs[i] < bondMatureYearDiffs[i + 1]) {
      return sortedTreasureYieldCurveYears[i]
    }
  }
  //when all diffs are decending
  return sortedTreasureYieldCurveYears[bondMatureYearDiffs.length - 1]
}
