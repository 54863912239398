import { reduce, groupBy, pick } from "lodash-es"
import meta from "@/data/econ-data-meta.json"

function getTimeSeriesFromGroup(seriesGroups, indicators) {
  const result = reduce(
    seriesGroups,
    (acc, _rows, code) => {
      if (indicators && !indicators.includes(code)) return acc
      const filteredRows = seriesGroups[code]

      const { economicIndicatorName: title, scaleFactor: unit, measureCode, frequencyCode } = filteredRows[0]

      const series = filteredRows.reduce((ts, cur) => {
        ts[cur.period] = cur.value
        return ts
      }, {})

      acc[code] = { code, title, unit, series, measureCode, frequencyCode }
      return acc
    },
    {}
  )
  return result
}

export async function getEconData(
  apiClient,
  iso3CountryCode,
  indicators,
  tsCols,
  includeRegion = false,
  withOverride = true
) {
  const startYear = tsCols[0]
  const endYear = tsCols[tsCols.length - 1]
  const entity = withOverride ? "CurrentEconomicSeries" : "CurrentVendorEconomicSeries"
  const url = `/api/EconomicMetric/${entity}?iso3CountryCode=${iso3CountryCode}&periodStart=${startYear}&periodEnd=${endYear}`
  const { data } = await apiClient.get(url)
  const keys = Object.keys(meta)
  const seriesGroups = groupBy(data.economicMetrics, (d) => d.economicIndicatorCode)
  const regionSeriesGroups = pick(
    groupBy(data.regionEconomicMetrics, (d) => d.economicIndicatorCode),
    keys
  )

  if (includeRegion) {
    return [getTimeSeriesFromGroup(seriesGroups, indicators), getTimeSeriesFromGroup(regionSeriesGroups, indicators)]
  } else {
    return getTimeSeriesFromGroup(seriesGroups, indicators)
  }
}
