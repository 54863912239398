import type APIRepository from "@/utils/APIRepository"

export enum AssetType {
  HardCurrency = "HC",
  LocalCurrency = "LC"
}

export enum Region {
  Africa = "Africa",
  Asia = "Asia",
  EasternEurope = "Eastern Europe",
  LatinAmerica = "Latin America",
  MiddleEast = "Middle East"
}

export enum BetaBucketHc {
  High = "High",
  Medium = "Medium",
  Low = "Low"
}

export enum BetaBucketLc {
  High = "High",
  Low = "Low",
  Frontier = "Frontier",
  Developed = "Developed"
}

export type SovereignUniverseEntry = {
  isoNumericId: number
  isoAlpha2: string
  isoAlpha3: string
  currency: string
  name: string
  region: Region
  betaBucketHc: BetaBucketHc | null
  betaBucketLc: BetaBucketLc | null
}

export async function loadSovereignUniverse(apiRepo: APIRepository) {
  const universe: SovereignUniverseEntry[] = await apiRepo.requestBlobCSV("sov-universe/latest.csv")
  //0 is assigned to Africa supranational which we do not need in sovereign page
  return universe.filter((entry) => entry.isoNumericId !== 0)
}

export function filterUniverseByAssetType(universe: SovereignUniverseEntry[], assetType: AssetType) {
  if (assetType === AssetType.HardCurrency) {
    return universe.filter((entry) => entry.betaBucketHc && Object.values(BetaBucketHc).includes(entry.betaBucketHc))
  } else if (assetType === AssetType.LocalCurrency) {
    return universe.filter((entry) => entry.betaBucketLc && Object.values(BetaBucketLc).includes(entry.betaBucketLc))
  } else {
    throw new Error("Invalid asset type")
  }
}

export function filterUniverseByRegion(universe: SovereignUniverseEntry[], region?: Region) {
  if (!region) return universe
  return universe.filter((entry) => entry.region === region)
}

export function filterUniverseHc(universe: SovereignUniverseEntry[], region?: Region, betaBucketHc?: BetaBucketHc) {
  const universeByAssetType = filterUniverseByAssetType(universe, AssetType.HardCurrency)
  const universeFilteredByRegion = filterUniverseByRegion(universeByAssetType, region)

  if (!betaBucketHc) return universeFilteredByRegion
  return universeFilteredByRegion.filter((entry) => entry.betaBucketHc === betaBucketHc)
}

export function filterUniverseLc(universe: SovereignUniverseEntry[], region?: Region, betaBucketLc?: BetaBucketLc) {
  const universeByAssetType = filterUniverseByAssetType(universe, AssetType.LocalCurrency)
  const universeFilteredByRegion = filterUniverseByRegion(universeByAssetType, region)

  if (!betaBucketLc) return universeFilteredByRegion
  return universeFilteredByRegion.filter((entry) => entry.betaBucketLc === betaBucketLc)
}
