<template>
  <div class="nav-list">
    <SplitButton
      class="p-button-text p-button-sm p-button-plain mx-2 my-1"
      label="Top Down Research"
      :model="topDownResearchMenuEntries"
      @click="router.push({ name: 'top-down-econ-summary' })"
    ></SplitButton>
    <SplitButton
      label="Bottom Up Research"
      :model="bottomUpResearchMenuEntries"
      class="p-button-text p-button-sm p-button-plain mx-2 my-1"
      @click="router.push({ name: 'sovereign-home' })"
    ></SplitButton>
    <Button
      class="p-button-text p-button-sm p-button-plain mx-2 my-1"
      label="Investible Universe"
      @click="router.push({ name: 'investable-universe' })"
    ></Button>
    <SplitButton
      class="p-button-text p-button-sm p-button-plain mx-2 my-1"
      label="Portfolio Management"
      :model="portfolioManagementEntries"
      @click="router.push({ name: 'portfolio-management' })"
    ></SplitButton>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue"
import { useRouter } from "vue-router"
import SplitButton from "primevue/splitbutton"
import Button from "primevue/button"

const router = useRouter()

const topDownResearchMenuEntries = ref([
  {
    label: "Economic Summary",
    class: "sub-nav-item",
    command() {
      router.push({ name: "top-down-econ-summary" })
    }
  },
  {
    label: "TAA Scorecard",
    class: "sub-nav-item",
    command() {
      router.push({ name: "top-down-taa-scorecard" })
    }
  }
])

const bottomUpResearchMenuEntries = ref([
  {
    label: "Sovereign",
    class: "sub-nav-item",
    command() {
      router.push({ name: "sovereign-home" })
    }
  },
  {
    label: "Corporate",
    class: "sub-nav-item",
    command() {
      router.push({ name: "corp-home" })
    }
  }
])

const portfolioManagementEntries = ref([
  {
    label: "Balance Tool",
    class: "sub-nav-item",
    command() {
      router.push({ name: "portfolio-management" })
    }
  },
  {
    label: "Risk Visualization Tool",
    class: "sub-nav-item",
    command() {
      router.push({ name: "risk-vis" })
    }
  }
])
</script>

<style>
.nav-list {
  display: flex;
  justify-items: left;
}

.nav-list .p-button,
.sub-nav-item {
  font-size: 0.875rem;
}
</style>
