<template>
  <div>
    <div class="section-header">
      <h3>Historical Spread</h3>
      <div class="section-header-right ml-sm-0 ml-md-auto">
        <select
          v-if="Array.isArray(sub_isins) && sub_isins.length > 1"
          v-model="selectedISIN"
          class="subtle-select mr-2"
        >
          <option value="-1" selected>Switch bonds</option>
          <option v-for="item in sub_isins" :key="item.isin" :value="item">
            {{ item.bondName || item.isin }}
          </option>
        </select>
      </div>
    </div>

    <div
      v-if="loading"
      class="content-card"
      style="height: 360px; display: grid; align-items: center; justify-content: center"
    >
      <v-progress-circular :size="48" color="primary" indeterminate></v-progress-circular>
    </div>
    <highcharts v-else :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { loadIssuerInfo } from "@/apis/corp.js"
import Chart from "@wbim/highcharts-themed"
import { loadDefaultBonds, updateDefaultBonds } from "@/apis/corp.js"

export default {
  components: { highcharts: Chart },
  inject: ["log", "apiRepo", "theme", "sovereignUniverse"],

  props: {
    issuer: { type: String, required: true }
  },

  data() {
    return {
      issuerInfo: {},
      sub_isins: null,
      selectedISIN: null,
      series: [],
      loading: true
    }
  },

  computed: {
    chartOptions() {
      const vm = this
      return {
        time: {
          //new Data will parse to UTC, here we use useUTC to work around day lag
          useUTC: true
        },
        chart: { type: "line" },
        legend: { enabled: true },
        tooltip: { valueDecimals: 1, xDateFormat: "%Y-%m-%d" },
        plotOptions: {
          series: {
            hover: { enabled: true }
          }
        },
        xAxis: { type: "datetime" },
        yAxis: { title: { text: "Spread" } },
        series: vm.series
      }
    }
  },

  async mounted() {
    this.issuerInfo = await loadIssuerInfo(this.apiRepo, this.issuer)
    this.sub_isins = await this.loadIsinsInfo(this.issuerInfo.isins)

    const creditBucket = this.issuerInfo["sub_credit_bucket"]
    const country = this.getName(this.issuerInfo.country)
    const aggSeries = await Promise.all(
      [country, creditBucket ?? "NR"].map(async (instrument) => {
        return {
          type: "line",
          name: instrument,
          data: await this.loadAggSeries(instrument)
        }
      })
    )

    const bonds = await loadDefaultBonds(this.apiRepo, this.issuer)
    let index = -1
    if (Array.isArray(bonds) && bonds.length > 0) {
      index = this.sub_isins.findIndex((d) => d.isin === bonds[0])
    }
    this.selectedISIN = this.sub_isins[Math.max(index, 0)]

    this.$watch(
      "selectedISIN",
      async function ({ isin, bondName }) {
        const bondSeries = { type: "line", name: bondName, data: await this.loadBondSeries(isin) }
        this.series = [bondSeries, ...aggSeries]
        this.loading = false
        try {
          updateDefaultBonds(this.apiRepo, this.issuer, isin)
        } catch {
          this.log.warn("failed to update default bonds")
        }
      },
      { immediate: true }
    )
  },

  methods: {
    getName(code3) {
      const name = this.sovereignUniverse.fromAlpha3(code3)?.name
      return name
    },
    async loadIsinsInfo(isins) {
      const url = `/api/corpbonds/${isins}`
      const instance = await this.apiRepo.makeServiceAPIClient()
      const response = await instance.get(url)
      return response.data
    },

    async loadBondSeries(ISIN) {
      const url = `/api/zspreadToWorst/${ISIN}`
      let response
      try {
        const instance = await this.apiRepo.makeServiceAPIClient()
        response = await instance.get(url)
      } catch {
        throw new Error("failed to load opportunity set data")
      }

      const { data, status } = response
      if (status !== 404) {
        return data.map((d) => [Date.parse(d.day), d.zSpread]).sort((a, b) => a[0] - b[0])
      } else {
        this.log.error("failed to request zspread ts for " + ISIN)
      }
    },

    async loadAggSeries(instrument) {
      const url = `/api/zspreadByCorpSub/${instrument}`
      let response
      try {
        const instance = await this.apiRepo.makeServiceAPIClient()
        response = await instance.get(url)
      } catch {
        throw new Error("failed to load opportunity set data")
      }

      const { data, status } = response
      if (status !== 404) {
        return data.map((d) => [Date.parse(d.day), d.zSpread]).sort((a, b) => a[0] - b[0])
      } else {
        this.log.error("failed to request zspread ts for " + instrument)
      }
    }
  }
}
</script>
