<template>
  <div class="editable-input-wrap" :style="{ width: widthValue, 'text-align': align }" @click="switchToEdit">
    <textarea
      v-if="multiline"
      v-bind="sharedProps"
      ref="editInput"
      class="editable-input"
      :style="{ border: isEditing ? '1px solid #ccc' : 'none' }"
      rows="3"
      @keyup="fitHeight"
      @blur="update($event.target.value)"
    />

    <input
      v-else
      v-bind="sharedProps"
      ref="editInput"
      class="editable-input"
      required
      @blur="update($event.target.value)"
      @keyup="fitHeight"
      @keyup.enter="update($event.target.value)"
    />
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from "vue"

const isEditing = ref(false)
const localValue = ref(null)
const editInput = ref(null)
const emit = defineEmits(["update:model-value"])

const props = defineProps({
  width: { type: [String, Number], default: "100%" },
  modelValue: { type: [String, Number], default: "" },
  pattern: { type: String },
  type: { type: String, default: "text" },
  align: { type: String, default: "center" },
  fluid: { type: Boolean, default: false },
  multiline: { type: Boolean, default: false },
  editing: { type: Boolean, default: false },
  clearOnFocus: { type: Boolean, default: true },
  valueFormatter: { type: Function, default: (a) => a }
})

onMounted(() => {
  isEditing.value = props.editing || isEditing.value
  localValue.value = props.valueFormatter(props.modelValue)
  if (props.multiline) {
    fitHeight()
  }
})

function fitHeight() {
  const el = editInput.value
  if (el && props.multiline) {
    el.style.height = "1px"
    const minHeight = Math.max(el.scrollHeight, 80)
    el.style.height = Math.min(minHeight, 480) + "px"
  }
}

const validatePattern = computed(() => {
  //escape below is liternal text required by pattern attribute
  //eslint-disable-next-line
  return props.pattern || props.type === "number" ? "\-?[0-9\.]+" : ".*"
})

const widthValue = computed(() => {
  if (props.fluid) return "100%"
  return typeof props.width !== "number" ? props.width : `${props.width}px`
})

const placeholder = computed(() => {
  //value before update
  return props.valueFormatter(props.modelValue)
})

const sharedProps = computed(() => {
  return {
    value: localValue.value,
    readonly: !isEditing.value,
    pattern: validatePattern.value,
    placeholder: placeholder.value
  }
})

function switchToEdit() {
  if (props.clearOnFocus) {
    localValue.value = ""
  }
  isEditing.value = true
}

function update(newValue) {
  //when update with a empty value in case of giving up update
  //restore with old value
  //TODO: how to handle if need intentially set to empty, can be workaround by give a space, but but but
  if (newValue === "") {
    localValue.value = props.valueFormatter(props.modelValue)
    return
  }
  if (String(props.modelValue) !== newValue && editInput.value.checkValidity()) {
    localValue.value = props.valueFormatter(newValue)
    emit("update:model-value", newValue)
  }
  isEditing.value = false
}
</script>

<style>
.editable-input-wrap {
  height: inherit;
}
.static-label {
  text-align: inherit;
  display: inline-block;
  width: 100%;
  white-space: pre-wrap;
  line-height: 1.5;
  padding: 0 5px;
  box-sizing: border-box;
}
.editable-input {
  pointer-events: auto;
  border: none;
  text-align: inherit;
  color: currentColor;
  line-height: 1.5;
  padding: 0 5px;
  width: 100%;
  height: inherit;
  box-sizing: border-box;
}
.editable-input:invalid {
  background-color: #c40;
}
</style>
