import * as dateFns from "date-fns"
import log from "loglevel"

export const fmtNum = (input, digits, isPercent = false) => {
  input = typeof input !== "number" ? parseFloat(input) : input
  if (Number.isNaN(input)) return "-"
  const formated = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits
  }).format(input * (isPercent ? 100 : 1))
  return formated + (isPercent ? "%" : "")
}

export const fmtDate = (input, formatStr = "MMM dd, yyyy") => {
  if (!input) {
    log.warn(`failed to convert type ${typeof input} to date.`)
    return ""
  }
  if (input instanceof Date === false) {
    input = dateFns.parseISO(input)
  }
  return dateFns.format(input, formatStr)
}
