export async function loadCorpIssuers(apiRepo, modelOnly = false, sector) {
  const query = []
  if (sector && sector !== "all") {
    query.push(`emd_sector=${encodeURIComponent(sector)}`)
  }
  if (modelOnly) {
    query.push("model_initiated=true")
  }

  const url = `/api/corp_issuers?${query.join("&")}`

  const api = await apiRepo.makeServiceAPIClient()
  //list view need to be updated after model change, so we can not cache here
  const { status, data } = await api.get(url)
  if (status === 200) {
    return data
  } else {
    throw new Error("can not load bond issuers")
  }
}

export async function loadCorpSectors(apiRepo) {
  const url = "/api/corp-sectors"
  const api = await apiRepo.makeServiceAPIClient()
  const { status, data } = await api.get(url)
  if (status === 200) {
    return data.filter((d) => d !== "Not Screened").sort()
  } else {
    console.error("can not load corp sector list") //eslint-disable-line
    return null
  }
}

export async function loadIssuerInfo(apiRepo, issuerId) {
  const url = "/api/corp_issuers/" + issuerId
  const api = await apiRepo.makeServiceAPIClient()
  const { status, data } = await api.get(url)
  if (status === 200) {
    return data
  } else {
    throw new Error("can not load bond issuers")
  }
}

export async function loadCorpBonds(apiRepo, sector) {
  const url = `/api/corp_bonds?${sector && sector !== "all" ? `emd_sector=${encodeURIComponent(sector)}` : ""}`
  const api = await apiRepo.makeServiceAPIClient()
  const { status, data } = await api.get(url)
  if (status === 200) {
    return data
  } else {
    throw new Error("can not load bonds list")
  }
}

export async function loadDefaultBonds(apiRepo, issuer) {
  const api = await apiRepo.makeServiceAPIClient()
  const modelURL = `/api/corp_issuers/${issuer}?fields=default_bond_1,default_bond_2`

  const { status, data } = await api.get(modelURL)
  if (status !== 200 || !Array.isArray(data) || data.length === 0) {
    return [null, null]
  }
  const bond1 = data.find((d) => d.field === "default_bond_1")
  const bond2 = data.find((d) => d.field === "default_bond_2")
  return [bond1?.value, bond2?.value]
}

export async function updateDefaultBonds(apiRepo, issuer, bond1, bond2) {
  const api = await apiRepo.makeServiceAPIClient()
  const modelURL = `/api/corp_issuers/${issuer}`
  const batch = []
  const p1 = api.patch(modelURL, { default_bond_1: bond1 })
  batch.push(p1)
  if (bond2) {
    const p2 = api.patch(modelURL, { default_bond_2: bond2 })
    batch.push(p2)
  }

  try {
    await Promise.all(batch)
  } catch (err) {
    throw new Error("can not update default bond")
  }
}
export async function loadCorpDoc(apiRepo, docPath) {
  const api = await apiRepo.makeServiceAPIClient()
  const { data, status } = await api.get(docPath)
  if (status === 200 && data) {
    return typeof data === "string" ? { ops: [{ insert: data }] } : data
  } else {
    throw new Error("can not load corp doc path")
  }
}

export async function saveCorpDoc(apiRepo, resourcePath, remoteKey, newContent) {
  const api = await apiRepo.makeServiceAPIClient()
  const { status } = await api.patch(resourcePath, {
    path: remoteKey,
    value: newContent
  })
  return status
}
