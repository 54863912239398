<template>
  <div class="spark-line">
    <Chart :options="chartOptions" />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"
import Chart from "@wbim/highcharts-themed"
import type Highcharts from "highcharts"

type Props = {
  data: Highcharts.PointOptionsObject[]
  height?: number
  lineWidth?: number
}

const props = withDefaults(defineProps<Props>(), {
  height: 50,
  lineWidth: 2
})

const chartOptions = computed(
  (): Highcharts.Options => ({
    chart: {
      type: "line",
      height: props.height,
      backgroundColor: "transparent",
      margin: [8, 8, 8, 8],
      spacing: [0, 0, 0, 0]
    },
    title: { text: "" },
    xAxis: { visible: false },
    yAxis: { visible: false },
    legend: { enabled: false },
    credits: { enabled: false },
    tooltip: {
      enabled: true,
      valueDecimals: 2,
      pointFormat: "{point.y:,.2f}"
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false
        },
        lineWidth: props.lineWidth,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    series: [
      {
        type: "line",
        data: props.data
      }
    ]
  })
)
</script>
