<template>
  <div>
    <div class="section-header">
      <h3>Information</h3>
    </div>
    <v-data-table
      ref="infoTable"
      tile
      class="info-table"
      :items="rows"
      :headers="headers"
      :mobile-breakpoint="0"
      hide-default-footer
      disable-pagination
      disable-filtering
      disable-sort
      density="compact"
    >
      <template #bottom></template>
      <template #[`item.title`]="{ item }">
        <div class="d-flex justify-space-between" :title="item.title">
          <span class="long-text">{{ item.title }}</span>
          <span>{{ item.unit }}</span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { sortBy } from "lodash-es"
import { Ps } from "@/plugins/perfect-scroll"
import { getEconData } from "@/apis/econData.js"

const currentYear = new Date().getFullYear()
const series = ["GDP%YR", "GDP$!", "CPIEOP%YR", "BCU$", "RES$M", "SP.POP.TOTL"]
const tsCols = Array(5)
  .fill(0)
  .map((d, i) => String(i - 4 + currentYear)) //eslint-disable-line
const fmt = new Intl.NumberFormat("en-US", { minimumFractionDigits: 0, maximumFractionDigits: 1 })

export default {
  inject: ["apiRepo", "log", "sovereignUniverse"],
  props: {
    region: { type: String, required: true }
  },
  data() {
    return {
      tsCols,
      headers: [
        { title: "", key: "title", width: 200, align: "left" },
        ...tsCols.map((d) => ({ title: d, key: String(d), align: "right" }))
      ],
      rows: []
    }
  },
  computed: {
    regionGroup() {
      //awful naming here
      const regionGroup = this.sovereignUniverse.fromAlpha2(this.region)?.region
      return regionGroup.replace(/\s/, "_")
    }
  },
  watch: {
    region: {
      handler() {
        if (!this.region) return
        this.init()
      },
      immediate: true
    }
  },
  mounted() {
    const scrollContainer = this.$refs.infoTable.$el.querySelector(".v-table__wrapper")
    new Ps(scrollContainer, { suppressScrollY: true })
  },

  methods: {
    async init() {
      const apiClient = await this.apiRepo.makeEMDAPIClient()
      const code3 = this.sovereignUniverse.code2To3(this.region)
      const [countryData, regionData] = await getEconData(apiClient, code3, series, tsCols, true)
      if (this.regionGroup === undefined) {
        this.log.error("No Regional Group assigned for " + this.region)
        return
      }

      const countryItemRows = Object.entries(countryData).map(([code, d]) => {
        const series = Object.fromEntries(Object.entries(d.series).map(([k, v]) => [k, fmt.format(v)]))
        return { code, title: d.title, ...series }
      })
      const regionItemRows = Object.entries(regionData).map(([code, d]) => {
        const series = Object.fromEntries(Object.entries(d.series).map(([k, v]) => [k, fmt.format(v)]))
        return { code, title: d.title, ...series, groupItem: true }
      })

      const merged = sortBy(
        [...countryItemRows, ...regionItemRows],
        [(d) => series.indexOf(d.code), (d) => (d.groupItem ? 0 : -1)]
      )

      this.rows = merged.map((d) => ({
        ...d,
        title: d.groupItem ? `└─ ${this.regionGroup.replace("_", " ")} (avg.)` : d.title
      }))
    }
  }
}
</script>

<style scoped>
:deep(.info-table.v-data-table th),
:deep(.info-table.v-data-table td) {
  padding: 0 8px;
}

:deep(.info-table.v-data-table th),
:deep(.info-table.v-data-table td) {
  padding: 0 8px;
}
:deep(.info-table table) {
  table-layout: fixed;
}

:deep(.info-table table td .long-text) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
