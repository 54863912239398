<template>
  <div class="econ-fundamentals">
    <div class="section-header">
      <h3>Fundamentals</h3>
    </div>
    <div class="content-card">
      <UserContentEditor id="background" title="Background" :scope-path="`/doc/${region}/background.json`" />
      <UserContentEditor
        id="#economic-growth-and-resilience"
        title="Economic Growth &amp; Resilience"
        :scope-path="`/doc/${region}/economic-growth-and-resilience.json`"
      />
      <UserContentEditor
        id="public-finance-health"
        title="Public Finance Health"
        :scope-path="`/doc/${region}/public-finance-health.json`"
      />
      <UserContentEditor
        id="external-balance-and-buffers"
        title="External Balance &amp; Buffers"
        :scope-path="`/doc/${region}/external-balance-and-buffers.json`"
      />
    </div>
  </div>
</template>

<script>
import UserContentEditor from "@/components/common/UserContentEditor.vue"

export default {
  components: {
    UserContentEditor
  },
  props: {
    region: { type: String, required: true }
  }
}
</script>
