import type APIRepository from "@/utils/APIRepository"
import type { SovereignUniverseEntry } from "@/apis/sovereign"
import { loadSovereignUniverse } from "@/apis/sovereign"
import throwExpression from "@/utils/throwExpression"
import { keyBy } from "lodash-es"

export default class SovereignUniverse extends Array<SovereignUniverseEntry> {
  private readonly sovUniverseAlpha2Map: Record<string, SovereignUniverseEntry> = {}
  private readonly sovUniverseAlpha3Map: Record<string, SovereignUniverseEntry> = {}

  constructor(universe: SovereignUniverseEntry[]) {
    super()
    this.push(...universe)
    this.sovUniverseAlpha2Map = keyBy(universe, "isoAlpha2")
    this.sovUniverseAlpha3Map = keyBy(universe, "isoAlpha3")

    Object.setPrototypeOf(this, SovereignUniverse.prototype)
  }

  //see https://javascript.info/extend-natives
  static get [Symbol.species]() {
    return Array
  }

  static async load(apiRepo: APIRepository) {
    const sovUniverse = await loadSovereignUniverse(apiRepo)
    return new SovereignUniverse(sovUniverse)
  }

  fromAlpha2(alpha2: string) {
    return this.sovUniverseAlpha2Map[alpha2] ?? throwExpression(`Cannot find ${alpha2} in universe`)
  }

  fromAlpha3(alpha3: string) {
    return this.sovUniverseAlpha3Map[alpha3] ?? throwExpression(`Cannot find ${alpha3} in universe`)
  }

  code2To3(code2: string) {
    return this.fromAlpha2(code2).isoAlpha3
  }

  code3To2(code3: string) {
    return this.fromAlpha3(code3).isoAlpha2
  }

  get hardCurrencyList() {
    return this.filter((d) => !!d.betaBucketHc)
  }

  get localCurrencyList() {
    return this.filter((d) => !!d.betaBucketLc)
  }
}
