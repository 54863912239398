import type APIRepository from "@/utils/APIRepository"
import type { AxiosInstance } from "axios"

export const changeDurations = [1, 2, 3, 4] as const
export type ChangeDuration = (typeof changeDurations)[number]
export type ScoreLookback = `Score${ChangeDuration}YearAgo`

export const pillars = ["Overview", "EPI", "SPI", "Governance"] as const
export type Pillar = (typeof pillars)[number]

export const betaBuckets = ["Low", "Medium", "High"] as const
export type BetaBucket = (typeof betaBuckets)[number]

export type ScoreType = "scaled" | "std"

export type ESGDataEntry = {
  Year: number
  CountryCode: string
  EMDRiskCountry: string
  BetaBucket: BetaBucket
  Pillar: Pillar
  ScoreType: ScoreType
  Score: number
  Factor: string
  Level: 0 | 1 | 2
} & Record<ScoreLookback, number>

export const pillarOptions = [
  { label: "Overview", value: "Overview" },
  { label: "Environment", value: "EPI" },
  { label: "Social", value: "SPI" },
  { label: "Governance", value: "Governance" }
]
export const scaleTypeOptions = [
  { label: "Scaled", value: "scaled" },
  { label: "Standard", value: "std" }
]

export const changeDurationOptions = changeDurations.map((i) => ({ label: `${i} Year${i > 1 ? "s" : ""}`, value: i }))

function getEffectiveFactorFromPillar(pillar: Pillar) {
  switch (pillar) {
    case "EPI":
      return "EnvironmentalScore"
    case "SPI":
      return "SocialScore"
    case "Governance":
      return "GovernanceScore"
    default:
      return "ESGScore"
  }
}
let apiClient: AxiosInstance | null = null
export async function initApiClient(apiRepo: APIRepository) {
  apiClient = await apiRepo.makeServiceAPIClient()
}

let CACHE_RAW: ESGDataEntry[]
//all data needed could be got from the cached json here
async function getRaw() {
  if (CACHE_RAW) return CACHE_RAW

  if (!apiClient) {
    throw new Error("api client not initialized")
  }

  const url = "/api/BlobProxy?path=esg-data/latest.json"
  const { data, status } = await apiClient.get(url)
  if (status !== 200) {
    throw new Error("failed to load esg data")
  }

  const rawInput = data as ESGDataEntry[]
  CACHE_RAW = rawInput.map<ESGDataEntry>((d) => {
    return {
      ...d,
      Score: 100 * d.Score,
      Score1YearAgo: 100 * d.Score1YearAgo,
      Score2YearAgo: 100 * d.Score2YearAgo,
      Score3YearAgo: 100 * d.Score3YearAgo,
      Score4YearAgo: 100 * d.Score4YearAgo
    }
  })
  return CACHE_RAW
}

export async function getMostRecentYear() {
  //TODO: implement this in backedn API instead
  //endpoint /sovereign/esg/most-recent-year
  const esgData = await getRaw()
  return Math.max(...esgData.map((d) => d.Year))
}

export type CountryDetailWithHistoryAndChangeObservationResult = Awaited<
  ReturnType<typeof getCountryDetailWithHistoryAndChangeObservation>
>
//peer overview data for a given selected year
export async function getCountryDetailWithHistoryAndChangeObservation(
  countryCode: string,
  pillar: Pillar,
  year: number,
  changeDuration: ChangeDuration,
  scoreType: ScoreType
) {
  //endpoint /sovereign/esg/country-detail-with-history-and-change-observation?countryCode=USA&year=2020&changeDuration=1
  //TODO: implement this in backedn API instead
  const esgData = await getRaw()
  const countryEsgData = esgData.filter(
    (d) => d.Year === year && d.CountryCode === countryCode && d.ScoreType === scoreType && d.Pillar === pillar
  )

  const comparePeriod = 4 - changeDuration

  return countryEsgData
    .map((d) => ({
      ...d,
      last5PeriodsData: [
        ...changeDurations.map((i) => ({ x: year - i, y: d[`Score${i}YearAgo`] })).reverse(),
        { x: year, y: d.Score }
      ]
    }))
    .map((d) => ({
      ...d,
      observedChange: d.last5PeriodsData[4].y - d.last5PeriodsData[comparePeriod].y
    }))
}

export async function getCountryPillarAnalysis(
  countryCode: string,
  year: number,
  changeDuration: ChangeDuration,
  scoreType: ScoreType
) {
  const esgData = await getRaw()
  return esgData
    .filter((d) => d.Year === year && d.CountryCode === countryCode && d.ScoreType === scoreType)
    .map((d) => {
      return { ...d, compareScore: d[`Score${changeDuration}YearAgo`] }
    })
}

export type LeaderLaggerDataResult = Awaited<ReturnType<typeof getLeaderLaggerData>>

//passing changeDuration here in case we want to handle this from backend api
export async function getLeaderLaggerData(
  pillar: Pillar,
  selectedYear: number,
  changeDuration: ChangeDuration,
  scoreType: ScoreType
) {
  //TODO: implement this in backedn API instead
  //endpoint /sovereign/esg/leader-lagger-data?pillar=Overview&selectedYear=2020&changeDuration=1
  const esgData = await getRaw()

  const effectiveFactor = getEffectiveFactorFromPillar(pillar)
  const compareField: ScoreLookback = `Score${changeDuration}YearAgo`

  const esgDataWithDelta = esgData
    .filter(
      (d) =>
        d.ScoreType === scoreType && //
        d.Factor === effectiveFactor &&
        !isNaN(d.Score) &&
        d.Year === selectedYear
    )
    .map((d) => ({ ...d, delta: d.Score - d[compareField] }))
  return {
    leader: esgDataWithDelta.sort((a, b) => b.delta - a.delta).slice(0, 10),
    lagger: esgDataWithDelta.sort((a, b) => a.delta - b.delta).slice(0, 10)
  }
}

export type BetaBucketAnalysisResults = Record<BetaBucket, ESGDataEntry[]>

//passing changeDuration here in case we want to handle this from backend api
export async function getBetaBucketAnalysisResult(
  pillar: Pillar,
  selectedYear: number,
  changeDuration: ChangeDuration,
  scoreType: ScoreType
) {
  //TODO: implement this in backedn API instead
  //endpoint /sovereign/esg/beta-bucket-analysis-data?pillar=Overview&selectedYear=2020
  const esgData = await getRaw()
  const effectiveFactor = getEffectiveFactorFromPillar(pillar)
  const initValue = { High: [], Medium: [], Low: [] } as BetaBucketAnalysisResults
  const compareField: ScoreLookback = `Score${changeDuration}YearAgo`

  return betaBuckets.reduce<BetaBucketAnalysisResults>((iter, bucket) => {
    const iterResult = esgData
      .filter(
        (d) =>
          d.ScoreType === scoreType &&
          d.BetaBucket === bucket &&
          d.Factor === effectiveFactor &&
          d.Year === selectedYear
      )
      .map((d) => ({ ...d, CompareScore: d[compareField] }))
      .sort((a, b) => b.Score - a.Score)

    iter[bucket] = iterResult
    return iter
  }, initValue)
}
