<template>
  <Multiselect
    class="grouped-single-select"
    :options-limit="3000"
    :style="`background: ${background}; border-radius: 4px`"
    :model-value="modelValue"
    :options="options"
    :multiple="false"
    :group-select="false"
    :track-by="trackByField"
    :label="displayField"
    group-label="key"
    group-values="groupItems"
    placeholder="Type to search"
    select-label=""
    selected-label=""
    deselect-label=""
    @open="dropdownOpen"
    @select="select"
    @search-change="searchChange"
  >
    <template #option="{ option }">
      <div v-if="option.$groupLabel" class="group-label" :style="{ background: groupColors[option.$groupLabel] }">
        <v-icon>mdi-menu-{{ foldedGroups.includes(option.$groupLabel) ? "down" : "right" }}</v-icon>
        <b>{{ option.$groupLabel }}</b>
      </div>

      <div
        v-else
        :class="{ option: true, 'option--hide': foldedGroups.includes(option[groupKey]) }"
        :style="{ borderColor: groupColors[option[groupKey]] }"
      >
        <span class="option__title">{{ option[displayField] }}</span>
      </div>
    </template>
    <template #noResult>
      <div class="option">
        <span class="option__title">No result from search</span>
      </div>
    </template>
  </Multiselect>
</template>

<script>
import Multiselect from "vue-multiselect"
import { groupBy, sortBy } from "lodash-es"

export default {
  components: { Multiselect },
  props: {
    background: { type: String, default: "currentColor" },
    groupColors: { type: Object, default: () => ({}) },
    items: { type: Array, default: () => [] },
    sortByFields: { type: [Array, String], default: () => [] },
    displayField: { type: String, default: "text" },
    trackByField: { type: String, default: "text" },
    groupKey: { type: String, required: true },
    modelValue: { type: Object, required: true }
  },
  emits: ["update:model-value"],
  data() {
    return {
      foldedGroups: [],
      query: ""
    }
  },
  computed: {
    options() {
      const groups = groupBy(this.items, this.groupKey)
      return Object.entries(groups)
        .map(([key, items]) => {
          const itemsRanked = items.map((d) => {
            const rank = this.rankFn(d[this.displayField], this.query)
            return { ...d, rank }
          })

          let groupItems
          if (this.query.length > 0) {
            groupItems = sortBy(itemsRanked, ["rank"])
              .filter((d) => d.rank > 0)
              .reverse()
          } else {
            groupItems = sortBy(itemsRanked, this.sortByFields)
          }

          return { key, groupItems }
        })
        .reverse()
    }
  },
  methods: {
    rankFn(label, query) {
      if (query.length === 0) return 0
      const text = label.toLowerCase()
      const queryLower = query.toLowerCase()
      if (text.startsWith(queryLower)) return 100
      const reg = new RegExp(`\\b${queryLower}`, "gi")
      if (reg.test(text)) return 10
      else return 0
    },
    toggleGroup(g) {
      if (this.foldedGroups.includes(g)) {
        this.foldedGroups = this.foldedGroups.filter((d) => d != g)
      } else {
        this.foldedGroups.push(g)
      }
    },
    select(selected) {
      //clicking on group will toggle group fold instead of select all items in group
      if (Array.isArray(selected) && selected.length > 0) {
        const g = selected[0][this.groupKey]
        this.toggleGroup(g)
      } else {
        this.$emit("update:model-value", selected)
      }
    },
    searchChange(query) {
      //open all fold when searching
      this.foldedGroups = []
      this.query = query
    },
    dropdownOpen() {
      const wrapper = this.$el.querySelector(".multiselect__content-wrapper")
      //fix perfect scroll scrolling issue.
      wrapper.addEventListener("mousewheel", (e) => e.stopPropagation())
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss">
.grouped-single-select {
  font-size: 12px;
  min-height: 32px;

  input {
    background: inherit !important;
  }

  .multiselect__content-wrapper {
    border: none;
  }

  .multiselect__single {
    font-size: 14px;
    background: inherit !important;
  }

  .multiselect__tags {
    background: inherit !important;
    border: none !important;
    min-height: inherit !important;
  }

  .multiselect__option {
    padding: 0;
    min-height: 0;
  }

  .multiselect__content {
    padding-left: 0;
  }

  .multiselect__option--highlight {
    background: #ccc;
    outline: none;
    color: #333;
  }

  .group-label {
    height: 28px;
    color: #fff;
    line-height: 28px;
    pointer-events: initial;
    cursor: pointer;
  }

  .option {
    height: 28px;
    line-height: 28px;
    padding-left: 6px;
    border-left-width: 6px;
    border-left-style: solid;
  }

  .option--hide {
    display: none;
    height: 0;
    line-height: 0;
  }
}
</style>
