<template>
  <v-dialog :model-value="error" max-width="290">
    <v-card>
      <v-card-title class="text-h5">
        <v-icon v-if="error.icon">{{ error.icon }}</v-icon>
        <v-icon v-else>mdi-information</v-icon>
        {{ error.errorTitle }}
      </v-card-title>

      <v-card-text>{{ error.errorMessage }}</v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="green-darken-1" variant="text" @click="$emit('confirm')">Dismiss</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    error: { type: Object, required: true }
  },
  emits: ["confirm"]
}
</script>
