<template>
  <v-dialog :model-value="error" max-width="420" persistent>
    <v-card dark>
      <v-card-title class="bg-red-darken-2">
        <v-icon size="large" start>mdi-alert-circle</v-icon>
        <span class="text-h6 font-weight-light">{{ error.errorCode }}</span>
      </v-card-title>

      <v-card-text>
        <div v-for="(m, index) in messageChunks" :key="index" class="ma-2">
          <div class="text-subtitle-2">{{ m[0] }}</div>
          <div class="text-body-2">{{ m[1] }}</div>
        </div>
      </v-card-text>

      <v-card-actions v-if="isNotAssigned">
        <v-btn
          rounded
          color="green"
          class="pa-4 ml-auto"
          :href="`mailto:pwang@williamblair.com?subject=EM Dashboard Access&amp;body=Please grant me access to ${siteDomain} .`"
        >
          <v-icon>mdi-email</v-icon>
          Request Access
        </v-btn>
      </v-card-actions>

      <v-card-actions v-else-if="needReAuthenticate">
        <v-btn rounded color="primary" class="pa-4 ml-auto" @click="logout">
          <v-icon>mdi-logout-variant</v-icon>
          Logout
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import EventBus from "@/utils/eventbus.js"
export default {
  props: {
    error: { type: Object, required: true }
  },
  computed: {
    siteDomain() {
      return window.location.origin
    },
    messageChunks() {
      return this.error.errorMessage.split("\n").map((m) => m.split(/:(.+)/))
    },
    isNotAssigned() {
      return this.error.errorMessage.startsWith("AADSTS50105")
    },
    needReAuthenticate() {
      return this.error.errorMessage.startsWith("AADSTS50078")
    }
  },
  methods: {
    logout() {
      EventBus.$emit("logout")
    }
  }
}
</script>
