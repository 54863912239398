<template>
  <div>
    <div class="section-header">
      <h3>CRM</h3>

      <div class="text-overline mx-4">
        <ColorScaledScore :score="fmtNum(crmScore, 0)" />
      </div>
    </div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Chart from "@wbim/highcharts-themed"
import ColorScaledScore from "./common/ColorScaledScore.vue"
import { fmtNum } from "@/utils/vmMethods.js"

const labelMappings = {
  business_profile: "Business profile",
  mgmt_strategy: "Mgmt & Strategy",
  sovereign: "Sovereign",
  financials: "Financials",
  debt_structure: "Debt structure"
}

export default {
  components: {
    highcharts: Chart,
    ColorScaledScore
  },
  inject: ["apiRepo", "theme"],

  props: {
    issuer: { type: String, required: true }
  },

  data() {
    return { scoreData: [] }
  },

  computed: {
    categories() {
      return Object.values(labelMappings)
    },

    crmScore() {
      if (Array.isArray(this.scoreData) && this.scoreData.length > 0) {
        const len = this.scoreData.length
        return this.scoreData.reduce((iter, cur) => iter + cur.y, 0) / len
      } else {
        return "NA"
      }
    },

    chartOptions() {
      return {
        chart: {
          polar: true,
          type: "line"
        },

        title: {
          text: "Credit scores"
        },

        tooltip: {
          shared: true,
          enabled: true,
          useHTML: true,
          headerFormat: "<table>",
          pointFormatter() {
            if (!this.sub_factors || this.sub_factors.length === 0)
              return `<tr><td colspan=2>No sub score available</td></tr>`
            const body = this.sub_factors.reduce(
              (iter, factor) =>
                iter +
                `
                  <tr>
                      <td style="color: {series.color}">${factor.label} </td>
                      <td style="text-align:right">${factor.score}</td>
                  </tr>`,
              ""
            )
            return body
          },
          footerFormat: "</table>",
          valueDecimals: 0
        },

        xAxis: {
          categories: this.categories,
          tickmarkPlacement: "on",
          lineWidth: 0,
          labels: { enabled: true }
        },

        yAxis: {
          gridLineInterpolation: "polygon",
          lineWidth: 0,
          min: 0,
          max: 100
        },

        plotOptions: {
          series: {
            fillOpacity: 0.2,
            pointStart: 0,
            dataLabels: { enabled: true, format: "{point.y:,.0f}" }
          }
        },

        series: [
          {
            type: "area",
            data: this.scoreData,
            pointPlacement: "on"
          }
        ]
      }
    }
  },
  watch: {
    $route(to, from) {
      if (from.name === "corp-risk-model") {
        this.load()
      }
    }
  },
  mounted() {
    this.load()
  },
  methods: {
    fmtNum,
    getScoreFromSubFactors(sub_factors) {
      const len = sub_factors.length
      return sub_factors.reduce((iter, cur) => iter + cur.score, 0) / len
    },
    async load() {
      const api = await this.apiRepo.makeServiceAPIClient()
      const { data } = await api.get(`/api/corp_crm_models/${this.issuer}`)
      this.scoreData = Object.entries(labelMappings).map(([k, v]) => {
        const sub_factors = data[k].sub_factors || []
        const score = data[k].score || this.getScoreFromSubFactors(sub_factors)
        return { name: v, y: score, sub_factors }
      })
    }
  }
}
</script>
