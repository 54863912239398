<template>
  <v-progress-circular :color="color" :size="size" :width="size / 2" :model-value="value"></v-progress-circular>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "blue darken-2"
    },
    value: {
      type: Number,
      default: 0
    },
    size: {
      type: Number,
      default: 24
    }
  }
}
</script>

<style></style>
