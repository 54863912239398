<template>
  <div :style="`height: ${size}px; width: ${size}px; flex: 0 0 ${size}px`">
    <div v-if="!imgSrc" class="text-avatar" :style="`height: ${size}px; width: ${size}px`" v-text="initial"></div>
    <img v-else :src="imgSrc" style="width: 100%; height: 100%; border-radius: 1000em" />
  </div>
</template>

<script setup>
import { inject, computed, ref, onMounted } from "vue"
import localforage from "localforage"

const log = inject("log")
const apiRepo = inject("apiRepo")
const props = defineProps({
  name: {
    type: String,
    default: null
  },
  size: {
    type: Number,
    default: 32
  }
})
const imgSrc = ref("")
const initial = computed(() => {
  if (!props.name) return ""
  const names = props.name.split(/,|\s/).filter((d) => d !== "")
  if (props.name.includes(",")) {
    return names[1][0] + names[0][0]
  } else if (props.name.includes(" ")) {
    return names[0][0] + names[1][0]
  } else {
    return props.name[0]
  }
})
const initialFontSize = computed(() => {
  return Math.round(props.size / 1.8) + "px"
})
onMounted(async () => {
  const cachedBufferData = await localforage.getItem(props.name)
  if (cachedBufferData) {
    imgSrc.value = window.URL.createObjectURL(cachedBufferData)
    return
  }
  try {
    const api = await apiRepo.makeMSGraphAPIClient()
    const url = `https://graph.microsoft.com/v1.0/users?$search="displayName:${props.name}"&$filter=endsWith(mail,'williamblair.com')&$select=id,displayName,mail`
    const { data: userData } = await api.get(url, { headers: { ConsistencyLevel: "eventual" } })
    const userId = userData.value[0].id

    const r = Math.max(1.5, Math.min(3, Math.round(window.devicePixelRatio)))
    const imgUrl = `https://graph.microsoft.com/v1.0/users/${userId}/photos/${props.size * r}x${props.size * r}/$value`
    const { data: blobData } = await api.get(imgUrl, { responseType: "blob" })
    localforage.setItem(props.name, blobData)
    imgSrc.value = window.URL.createObjectURL(blobData)
  } catch (err) {
    log.info(err)
    return
  }
})
</script>

<style>
.text-avatar {
  border: 1px solid currentColor;
  font-size: v-bind(initialFontSize);
  display: inline-flex;
  border-radius: 10000em;
  justify-content: center;
  align-items: center;
}
</style>
