<template>
  <div style="overflow: hidden; position: relative; overflow: auto">
    <div class="section-header">
      <h3>ESG</h3>
      <div class="text-overline mx-4">
        <ColorScaledScore :score="fmtNum(totalScore, 0)" />
      </div>

      <v-snackbar
        v-model="snack"
        class="ma-0"
        content-class="ma-0 py-0"
        :timeout="5000"
        :color="snackColor"
        absolute
        location="right"
      >
        {{ snackText }}
        <template #action="{ props }">
          <v-btn v-bind="props" color="primary" size="small" icon @click="snack = false">
            <v-icon size="small">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <div v-if="$vuetify.display.lgAndUp">
        <v-btn v-if="!isEditing" size="x-small" variant="text" color="primary" @click="isEditing = true">Edit</v-btn>
        <v-btn v-else size="x-small" variant="outlined" color="primary" @click="isEditing = false">Done</v-btn>
      </div>
    </div>
    <div
      v-if="isEditing"
      :style="{
        display: 'grid',
        'grid-gap': '8px',
        'grid-template-columns': 'repeat(auto-fit, minmax(360px, 1fr))'
      }"
    >
      <v-data-table
        class="elevation-0"
        height="auto"
        :headers="headers"
        :items="factors"
        :group-by="[{ key: 'convergedPillar' }]"
        :items-per-page="1000"
        item-value="key"
        density="compact"
        disable-initial-sort
        disable-pagination
        hide-default-footer
      >
        <template #bottom></template>
        <template #group-header="{ item, toggleGroup, isGroupOpen }">
          <tr>
            <td style="vertical-align: center" colspan="2">
              <v-btn
                size="x-small"
                variant="text"
                :icon="isGroupOpen(item) ? '$expand' : '$next'"
                @click="toggleGroup(item)"
              ></v-btn>
              <span class="text-blue-lighten-1">{{ item.value }}</span>
            </td>
            <td></td>
            <td></td>
            <td class="text-center text-subtitle-2 text-blue-lighten-1">
              {{ fmtNum(calcGroupTotalMat(factors, item.value), 1, false) }}
            </td>
            <td class="text-center text-subtitle-2 text-blue-lighten-1">
              {{ fmtNum(calcGroupTotalWgt(factors, item.value), 1, true) }}
            </td>
            <td class="text-center text-subtitle-2 text-blue-lighten-1">
              {{ fmtNum(calcGroupAvgScore(factors, item.value), 1, false) }}
            </td>
            <td></td>
          </tr>
        </template>

        <template #item="{ item }">
          <tr>
            <td
              v-if="
                item.raw.convergedPillar !== item.raw.pillar &&
                factors.filter((d) => d.pillar === item.raw.pillar)[0] === item.raw
              "
              :rowspan="
                item.raw.convergedPillar !== item.raw.pillar
                  ? factors.filter((d) => d.pillar === item.raw.pillar).length
                  : 1
              "
            >
              <div style="text-orientation: mixed; writing-mode: vertical-lr; color: #2196f3aa">
                {{ item.raw.pillar.replace(item.raw.convergedPillar, "") }}
              </div>
            </td>
            <td :colspan="item.raw.convergedPillar !== item.raw.pillar ? 1 : 2" style="text-align: left">
              {{ item.raw.name }}
            </td>
            <td style="text-align: center">
              {{ item.raw.sustainability_impact }}
            </td>
            <td style="text-align: center">
              {{ item.raw.business_impact }}
            </td>
            <td style="text-align: center">
              {{ item.raw.factorMateriality }}
            </td>
            <td style="text-align: center">
              {{ fmtNum(item.raw.factorWeight, 1, true) }}
            </td>
            <td>
              <EditableInput
                fluid
                type="number"
                :model-value="item.raw.score"
                :align="`center`"
                :value-formatter="(v) => fmtNum(v, 0)"
                @update:model-value="updateField(item.raw, 'score', parseFloat($event))"
              />
            </td>
            <td>
              <v-btn variant="text" size="x-small" icon="mdi-pencil" @click="item.raw.commentShow = true"></v-btn>
              <v-dialog v-model="item.raw.commentShow" max-width="600px">
                <v-card>
                  <v-card-title class="mb-0 d-flex align-center">
                    <div class="text-subtitle-1">Scoring Commentary</div>
                    <v-spacer></v-spacer>
                    <div class="text-caption text-grey">click text to edit</div>
                  </v-card-title>
                  <v-card-text class="pl-4 pt-2">
                    <EditableInput
                      v-model="item.raw.analystComment"
                      style="font-size: 12px"
                      :editing="!item.raw.analystComment"
                      fluid
                      multiline
                      type="text"
                      align="left"
                      :clear-on-focus="false"
                    />
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green-darken-1" variant="text" @click="saveAnalystComment(item.raw)">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </td>
          </tr>
        </template>
      </v-data-table>

      <div class="content-card pt-1">
        <div style="font-size: 12px; padding: 0 12px">
          <div class="my-2 text-blue d-flex align-center" style="font-weight: 500">
            <div style="width: 220px">Incident Management</div>
            <v-radio-group
              v-model="incident_management"
              density="compact"
              row
              hide-details
              class="mt-0 pt-0 radio-group--dense flex-grow-1"
            >
              <v-radio label="Negative" value="Negative"></v-radio>
              <v-radio label="Stable" value="Stable"></v-radio>
              <v-radio label="Positive" value="Positive"></v-radio>
            </v-radio-group>
          </div>
          <textarea v-model="incident_management_comments" class="subtle-textarea" rows="5" @change="updateRemote" />
        </div>

        <div style="font-size: 12px; padding: 0 12px">
          <div class="my-2 text-blue d-flex align-center" style="font-weight: 500">
            <div style="width: 220px">Outlook</div>
            <v-radio-group
              v-model="outlook"
              density="compact"
              row
              hide-details
              class="mt-0 pt-0 radio-group--dense flex-grow-1"
            >
              <v-radio label="Negative" value="Negative"></v-radio>
              <v-radio label="Stable" value="Stable"></v-radio>
              <v-radio label="Positive" value="Positive"></v-radio>
            </v-radio-group>
          </div>
          <textarea v-model="outlook_comments" class="subtle-textarea" rows="5" @change="updateRemote" />
        </div>
      </div>
    </div>
    <div
      v-else
      class="esg-summary-wrap"
      :style="{
        display: 'grid',
        gap: '16px',
        'grid-auto-flow': $vuetify.display.lgAndUp ? 'column' : 'row',
        'grid-template-columns': $vuetify.display.lgAndUp ? '3fr 1fr' : '1fr'
      }"
    >
      <div
        class="esg-catagories"
        :style="{
          display: 'grid',
          'grid-template-columns': $vuetify.display.lgAndUp ? '320px 1fr' : '1fr'
        }"
      >
        <div>
          <v-card
            v-for="(item, index) in pillarMatWgtSummary"
            :key="item.name"
            flat
            :ripple="false"
            class="rounded-0 pr-4 catagory-card"
            :class="{ 'catagory-card--active': index === activeCatagoryIndex }"
            :style="{ 'border-left': `5px solid ${catagoryColors[index]}` }"
            @click="activateCatagory(index)"
          >
            <v-card-title class="text-subtitle-2 pt-2 pb-0 font-weight-medium text-primary">
              {{ item.convergedPillar }}
            </v-card-title>

            <v-list class="my-0" style="background: transparent">
              <v-list-item title="Pillar Score">
                <template #append>
                  <div class="d-flex justify-center">
                    <ColorScaledScore :score="fmtNum(calcGroupAvgScore(factors, pillarCatagories[index]), 0)" />
                  </div>
                </template>
              </v-list-item>
              <v-list-item>
                <v-list-item-title>
                  <div class="d-flex space-between">
                    <span class="text--title">Materiality</span>
                    <ColorScaledLinearBar
                      style="width: 130px"
                      background-color="#1f1f1f"
                      :value="item.materiality / 10"
                      :color="catagoryColors[index]"
                    ></ColorScaledLinearBar>
                  </div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item title="Weight">
                <template #append>
                  <div class="d-flex justify-center align-center">
                    <SimplePie :color="catagoryColors[index]" :value="item.weight * 100"></SimplePie>
                    <span class="text-caption ml-4">{{ fmtNum(item.weight * 100, 0) }}%</span>
                  </div>
                </template>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
        <div v-if="$vuetify.display.lgAndUp" class="subfactor-card-wrap">
          <div
            v-for="subfactor in factors.filter((d) => d.convergedPillar === pillarCatagories[activeCatagoryIndex])"
            :key="subfactor.name"
            class="subfactor-card animate__animated animate__lightSpeedInLeft"
            :style="{ 'animation-duration': '0.45s' }"
          >
            <div>
              <div class="text-subtitle-2 font-weight-bold d-flex align-start">
                <span :data-tippy-content="subfactor.Description">
                  {{
                    [subfactor.pillar.replace(subfactor.convergedPillar, ""), subfactor.name]
                      .filter((d) => d.trim() !== "")
                      .join(" - ")
                  }}
                  <v-icon v-if="0" :size="16">mdi-information-outline</v-icon>
                </span>
              </div>

              <div style="font-size: 12px; color: #555557">{{ subfactor.Description }}</div>
              <div
                style="margin: 8px 0; display: grid; gap: 24px; grid-template-columns: repeat(3, minmax(120px, 1fr))"
              >
                <div class="info-box">
                  <div class="text-caption font-weight-bold">Score</div>
                  <div class="text-h4">
                    {{ subfactor.score }}
                  </div>
                </div>

                <div class="info-box">
                  <div class="text-caption font-weight-bold">Business Impact</div>
                  <ImpactIndicator class="my-2" :value="subfactor.business_impact"></ImpactIndicator>
                </div>
                <div class="info-box">
                  <div class="text-caption font-weight-bold">Sustainability Impact</div>
                  <ImpactIndicator class="my-2" :value="subfactor.sustainability_impact"></ImpactIndicator>
                </div>
              </div>
            </div>
            <div v-if="subfactor.analystComment">
              <v-icon xl class="float-left">mdi-format-quote-open</v-icon>
              <div class="text-caption">{{ subfactor.analystComment }}</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div v-for="item in summaryItems" :key="item.name" class="esg-summary-item py-2 px-4" style="overflow: auto">
          <div class="text-subtitle-2 py-2 font-weight-medium text-primary">
            {{ item.name }}
          </div>

          <div class="info-box d-flex align-center float-left mr-2 mb-2" style="width: 120px">
            <v-icon class="float-left" size="48" :color="mapTrendColor(item.value)" :icon="mapTrendIcon(item.value)" />
            {{ item.value }}
          </div>
          <div class="text-caption">{{ item.comments }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { pick } from "lodash-es"
// import tippy from "tippy.js"
import SimplePie from "@/components/common/SimplePie.vue"
import ColorScaledLinearBar from "@/components/common/ColorScaledLinearBar.vue"
import ColorScaledScore from "@/components/common/ColorScaledScore.vue"
import EditableInput from "@/components/common/EditableInput.vue"
import ImpactIndicator from "@/components/common/ImpactIndicator.vue"
import { fmtNum } from "@/utils/vmMethods.js"

import {
  calcGroupTotalMat,
  calcGroupTotalWgt,
  calcGroupAvgScore,
  calcESGScore,
  calcPillarMatWgtSummary,
  calcScoreSummary,
  getFactorsWithComputed,
  loadESG
} from "@/utils/esgScorePreprocess.js"

const pillarCatagories = Object.freeze(["Environmental", "Social", "Governance"])
const tableHeaders = [
  {
    title: "Factor",
    align: "start",
    width: 220,
    key: "name",
    sortable: false,
    groupable: false
  },
  {
    title: "Sustainability Impact",
    align: "center",
    key: "sustainability_impact",
    sortable: false,
    groupable: false
  },
  {
    title: "Business Impact",
    align: "center",
    key: "business_impact",
    sortable: false,
    groupable: false
  },
  {
    title: "Factor Materiality",
    align: "right",
    key: "factorMateriality",
    sortable: false,
    groupable: false
  },

  {
    title: "Factor Weight",
    align: "right",
    key: "factorWeight",
    sortable: false,
    groupable: false
  },
  {
    title: "Score",
    align: "center",
    key: "score",
    width: 80,
    sortable: false,
    groupable: false
  },
  {
    title: "",
    align: "center",
    key: "action",
    width: 80,
    sortable: false,
    groupable: false
  }
]

export default {
  components: { SimplePie, ColorScaledLinearBar, EditableInput, ColorScaledScore, ImpactIndicator },
  inject: ["log", "apiRepo", "theme"],
  props: {
    issuer: { type: String, required: true }
  },
  data() {
    return {
      pillarCatagories,
      activeCatagoryIndex: 0,
      catagoryColors: ["#008566", "#8196d9", "#9a9b9d"],
      isEditing: false,
      snack: false,
      snackColor: "#3c3c3c",
      snackText: "",
      incident_management: null,
      outlook: null,
      incident_management_comments: null,
      outlook_comments: null,
      comments: null,
      factors: [],
      headers: Object.freeze(tableHeaders)
    }
  },

  computed: {
    groups() {
      return [...new Set(this.factors.map((d) => d.convergedPillar))]
    },

    totalScore() {
      if (this.factors.length === 0) return 0
      return calcESGScore(this.factors)
    },

    summaryItems() {
      return [
        { name: "Incident Management", value: this.incident_management, comments: this.incident_management_comments },
        { name: "Outlook", value: this.outlook, comments: this.outlook_comments }
      ]
    },

    pillarMatWgtSummary() {
      return calcPillarMatWgtSummary(this.factors)
    },

    patches() {
      const factorsEx = getFactorsWithComputed(this.factors)
      const fieldsToSave = ["name", "pillar", "Description", "analystComment", "score"]
      const details = factorsEx.map((d) => pick(d, fieldsToSave))
      const { incident_management, outlook, incident_management_comments, outlook_comments, comments } = this
      const { environmental, /*social_internal, social_external,*/ social, governance } = calcScoreSummary(
        this.factors,
        true
      )

      return {
        details,
        score: this.totalScore,
        environmental,
        // social_internal,
        // social_external,
        social,
        governance,
        incident_management,
        outlook,
        incident_management_comments,
        outlook_comments,
        comments // comments is untouched here. but since we are patch the esg root object, we still have to post it back
      }
    }
  },

  async mounted() {
    const api = await this.apiRepo.makeServiceAPIClient()
    const esgData = await loadESG(api, this.issuer)
    this.factors = esgData.details.map((d) => ({ ...d, commentShow: false }))
    this.incident_management = esgData.incident_management
    this.outlook = esgData.outlook
    this.incident_management_comments = esgData.incident_management_comments
    this.outlook_comments = esgData.outlook_comments
    this.comments = esgData.comments
    this.$watch(
      (vm) => [vm.incident_management, vm.outlook, vm.incident_management_comments, vm.outlook_comments].join(),
      this.updateRemote.bind(this)
    )
  },

  methods: {
    fmtNum,
    calcGroupTotalMat,
    calcGroupTotalWgt,
    calcGroupAvgScore,
    mapTrendColor(trend) {
      if (!trend) return ""
      switch (trend.toLowerCase()) {
        case "positive":
          return "#4CAF50"
        case "negative":
          return "#FF002F"
        case "stable":
        default:
          return this.theme.isDark.value ? "#c7c7c7" : "#1c1c1c"
      }
    },
    mapTrendIcon(trend) {
      if (!trend) return ""
      switch (trend.toLowerCase()) {
        case "positive":
          return "mdi-arrow-up-bold-circle-outline"
        case "negative":
          return "mdi-arrow-down-bold-circle-outline"
        case "stable":
        default:
          return "mdi-minus-circle-outline"
      }
    },
    mapImpactColor(level) {
      switch (level) {
        case "High":
          return "red"
        case "Medium":
          return "yellow"
        case "Low":
          return "green"
        default:
          return "#ccc"
      }
    },
    mapImpactIcon(level) {
      switch (level) {
        case "High":
          return "mdi-speedometer"
        case "Medium":
          return "mdi-speedometer-medium"
        case "Low":
          return "mdi-speedometer-slow"
        default:
          return ""
      }
    },
    activateCatagory(index) {
      this.activeCatagoryIndex = index
    },

    saveAnalystComment(item) {
      item.commentShow = false
      this.updateField(item, "analystComment", item.analystComment)
    },
    async updateField(row, fieldName, value) {
      const index = this.factors.indexOf(row)
      this.factors[index][fieldName] = value
      await this.updateRemote()

      //any update need to recalculate the computed fields
      this.factors = getFactorsWithComputed(this.factors)
    },

    async updateRemote() {
      const api = await this.apiRepo.makeServiceAPIClient()
      const { status } = await api.patch(`/api/corp_crm_models/${this.issuer}`, {
        path: "esg",
        value: this.patches,
        update_esg_date: true
      })
      if (status !== 200) {
        this.log.error("failed to push esg details to remote")
        this.snack = true
        this.snackColor = "red"
        this.snackText = "failed to push esg details to remote"
      }
    }
  }
}
</script>

<style lang="scss" scoped>
:deep(thead th:first-child) {
  width: 48px;
  max-width: 48px;
}
:deep(thead th:first-child *) {
  display: none;
}
:deep(tbody) {
  tr:not(.v-row-group__header):hover {
    background-color: transparent !important;
  }
  .v-row-group__header,
  .v-row-group__summary {
    background-color: transparent !important;
    color: #42a5f5;
    font-weight: bold;
  }
}

:deep(.radio-group--dense .v-radio .v-label) {
  font-size: 12px;
  font-weight: 500;
}

:deep(.radio-group--dense .v-item--active label) {
  color: #2196f3;
}

.subfactor-card-wrap {
  .v-theme--dark & {
    background: #1f1f1f;
  }
  .v-theme--light & {
    background: #fff;
  }
  padding: 16px;
  position: relative;
  overflow-x: hidden; /* vue transition will change box model of the text content */
}
.subfactor-card {
  position: relative;
  display: grid;
  grid-template-columns: 520px 1fr;
  gap: 24px;
  padding-bottom: 16px;
}

.esg-summary-wrap {
  display: grid;
  gap: 16px;
}

.esg-summary-item {
  .v-theme--dark & {
    background: #272727;
  }
  .v-theme--light & {
    background: #fff;
  }
}

.catagory-card {
  box-sizing: border-box;
  .v-theme--dark & {
    background: #272727;
  }

  .v-theme--light & {
    background: #fafafa;
  }
}

.catagory-card--active {
  .v-theme--dark & {
    background: #1f1f1f !important;
  }
  .v-theme--light & {
    background: #fff !important;
  }

  .v-theme--dark &::before {
    background: #1f1f1f !important;
  }
}

.info-box {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #3c3c3c;
  padding: 8px 12px;

  .v-theme--light & {
    background: #fafafa;
  }
  .v-theme--dark & {
    background: #272727;
  }
}
</style>
