<template>
  <div>
    <div class="section-header">
      <h3>Credit Update</h3>
      <v-spacer></v-spacer>
      <v-btn-toggle v-if="archivedCreditUpdates.length > 0" v-model="viewType" rounded mandatory density="compact">
        <v-btn size="x-small">Latest</v-btn>
        <v-btn size="x-small">Archive</v-btn>
      </v-btn-toggle>
      <a class="text-overline ml-2" :href="`https://summit.williamblair.com/#/company/${issuer}`" target="_blank">
        Edit
      </a>
    </div>
    <div class="content-card py-4 pr-2" :style="{ height: $vuetify.display.xs || expandedView ? 'auto' : '360px' }">
      <div v-if="loaded" style="height: 100%; overflow: auto">
        <div v-if="viewType === 0">
          <div v-if="latestCreditUpdate">
            <div class="credit-update-title" v-text="latestCreditUpdate.creditUpdateTitle"></div>
            <div class="byline">
              create by&nbsp;
              <b>{{ latestCreditUpdate.createdByFullName }}</b>
              <span v-if="latestCreditUpdate.onBehalfOfFullName">
                on behalf of
                <b>{{ latestCreditUpdate.onBehalfOfFullName }}</b>
              </span>
              <span v-if="latestCreditUpdate.creditUpdateDate" class="update-date">
                &nbsp; {{ latestCreditUpdate.creditUpdateDate }} ago
              </span>
            </div>
            <!--eslint-disable-next-line vue/no-v-html-->
            <div class="body" v-html="latestCreditUpdate.creditUpdateBody"></div>
          </div>
          <v-alert v-else type="info" density="compact" variant="outlined" text>
            No credit update created in Summit for this issuer
          </v-alert>
        </div>
        <div v-else>
          <div class="archive-list">
            <v-dialog v-for="item in archivedCreditUpdates" :key="item.id" v-model="item.visible" width="640">
              <template #activator="{ props }">
                <div v-bind="props" class="archive-item">
                  <div class="credit-update-title text-primary">
                    {{ item.creditUpdateTitle }}
                  </div>
                  <div class="byline d-flex">
                    created by&nbsp;
                    <b>{{ item.createdByFullName }}</b>
                    <span v-if="item.onBehalfOfFullName">
                      on behalf of
                      <b>{{ item.onBehalfOfFullName }}</b>
                    </span>
                    <div v-if="item.creditUpdateDate" class="update-date">&nbsp; {{ item.creditUpdateDate }} ago</div>
                  </div>
                </div>
              </template>
              <v-card>
                <v-card-title>
                  <div class="credit-update-title text-primary d-flex" style="width: 100%">
                    <div class="d-flex align-center">
                      {{ item.creditUpdateTitle }}
                    </div>
                    <v-spacer></v-spacer>
                    <v-btn icon class="flex-0" @click="item.visible = false">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </v-card-title>
                <v-card-text>
                  <!--eslint-disable-next-line vue/no-v-html-->
                  <div class="body" v-html="item.creditUpdateBody"></div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </div>
      <div v-else class="process-indicator">
        <v-progress-circular :size="48" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { tryFormatDate } from "@/utils/dateHelper"

export default {
  inject: ["apiRepo"],
  props: {
    issuer: { type: String, required: true },
    expandedView: { type: String, required: false }
  },

  data() {
    return {
      loaded: false,
      viewType: 0, // 0=latest, 1=archive
      latestCreditUpdate: null,
      archivedCreditUpdates: []
    }
  },

  async mounted() {
    await this.getCreditUpdates(this.issuer)
  },

  methods: {
    getCreditUpdateFromEvent(event) {
      const { _id, title, body, createdBy, onBehalfOf, eventDate } = event
      return {
        id: _id,
        creditUpdateTitle: title,
        creditUpdateBody: this.tidyCreditUpdate(body),
        creditUpdateDate: tryFormatDate(eventDate),
        createdByFullName: createdBy?.fullName,
        onBehalfOfFullName: onBehalfOf?.fullName
      }
    },

    tidyCreditUpdate(body) {
      const regex = /style\s*=\s*["'][^"']*["']/gm
      return body.replace(regex, "")
    },

    async getCreditUpdates(issuer) {
      try {
        const api = await this.apiRepo.makeSummitAPIClient()
        const { status, data } = await api.post(`/api/issuers/${issuer}/events`, {
          codes: ["EMERGING_MARKETS"],
          categoryCode: "COMMENTARY"
        })
        if (status !== 200) {
          this.creditUpdateTitle = "Unable to fetch emerging market commentory data from summit API"
        } else {
          if (data.events && data.events.length > 0) {
            const updates = data.events.map(this.getCreditUpdateFromEvent)
            this.latestCreditUpdate = updates[0]
            this.archivedCreditUpdates = updates.slice(0)
          }
        }
      } catch {
        this.creditUpdateTitle = "Unable to requerst emerging market commentory data from summit API"
      } finally {
        this.loaded = true
      }
    }
  }
}
</script>

<style lang="scss">
.credit-update-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
  line-height: 1.5;
}
.byline {
  font-size: 12px;
  margin: 2px 0;
  color: #999;
}
.body {
  font-size: 13px;
}
.process-indicator {
  display: grid;
  min-height: 320px;
  align-items: center;
  justify-content: center;
}

.archive-item {
  padding: 8px;
  border-bottom: 1px solid #555557;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    .v-theme--light & {
      background-color: #cfcfcf;
    }
    .v-theme--dark & {
      background-color: #4c4c4c;
    }
  }
}
</style>
