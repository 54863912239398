<template>
  <SimpleQuillEditor
    ref="editor"
    :title="title"
    :show-byline="false"
    :tile-view="tileView"
    :content="content"
    @save="save"
  ></SimpleQuillEditor>
</template>

<script setup>
import { ref, inject, onMounted } from "vue"
import SimpleQuillEditor from "@/components/common/SimpleQuillEditor.vue"
import { loadCorpDoc, saveCorpDoc } from "@/apis/corp.js"

const apiRepo = inject("apiRepo")
const log = inject("log")

const props = defineProps({
  title: { type: String },
  tileView: { type: Boolean, default: false },
  remoteKey: { type: String, required: true },
  resourcePath: { type: String, required: true }
})

const editor = ref(null)
const content = ref(null)

onMounted(async () => {
  const docPath = `${props.resourcePath}?path=${props.remoteKey}`
  try {
    const doc = await loadCorpDoc(apiRepo, docPath)
    content.value = doc
  } catch {
    log.warn("unable to load the doc from remote when model is initialized ")
    content.value = { ops: [] }
  }
})

async function save(newContent) {
  const status = await saveCorpDoc(apiRepo, props.resourcePath, props.remoteKey, newContent)
  content.value = newContent
  editor.value.showSnack(status === 200 ? "success" : "error")
}
</script>
