<template>
  <v-card class="position-card">
    <v-card-title class="text-body-2 text-uppercase font-weight-medium py-1 d-flex">
      <div style="height: 32px; line-height: 32px">Equity</div>
      <v-spacer></v-spacer>
      <v-btn v-if="items.length > 0 && to && !inDialog" size="small" :to="to" icon class="mx-0">
        <v-icon>mdi-link</v-icon>
      </v-btn>
      <v-btn v-if="items.length > 0 && inDialog" size="small" icon class="mx-0" @click="$emit('close-dialog')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text>
      <div v-if="items.length === 0" class="mt-3 text-caption">No current equity positions</div>
      <div v-else class="d-flex align-center justify-space-between flex-wrap">
        <div>
          <div class="d-flex align-center">
            <v-icon>mdi-currency-usd</v-icon>
            <span style="font-size: 24px; line-height: 40px">
              {{ total }}
            </span>
            <span color="grey--text lighten-3">&nbsp;MM</span>
          </div>
          <div class="text-caption" style="min-width: 120px">Total positions</div>
        </div>
        <v-btn v-if="false && !inDialog" size="small" variant="outlined" class="ma-2" @click="$emit('show-dialog')">
          Show Details
        </v-btn>
        <table class="v-table table-in-card">
          <thead>
            <tr>
              <th class="text-left" style="width: 250px">Strategy</th>
              <th class="text-right">$MM</th>
              <th class="text-right">Wgt(%)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items.slice(0, 4)" :key="item[0]">
              <td class="text-left">{{ item[0] }}</td>
              <td class="text-right">{{ fmtNum(item[1] / 1e6, 1) }}</td>
              <td class="text-right">{{ fmtNum(item[2] * 100, 1) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { fmtNum } from "@/utils/vmMethods.js"

export default {
  name: "PositionCardEquity",
  props: {
    inDialog: { type: Boolean, default: false },
    to: { type: String },
    items: { type: Array, requried: true }
  },
  emits: ["show-dialog", "close-dialog"],
  data() {
    return { showPositionDetail: false }
  },
  computed: {
    total() {
      const value = this.items.reduce((total, cur) => (total += cur[1]), 0)
      return this.fmtNum(value / 1e6, 1)
    }
  },
  methods: {
    fmtNum
  }
}
</script>

<style lang="scss">
.table-in-card {
  box-shadow: none !important;
  border: none !important;
  width: 100%;
  background: inherit !important;

  tr td,
  tr th {
    padding: 4px !important;
    background: inherit !important;
    &:first-child {
      padding-left: 0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }
}
</style>
