<template>
  <div class="color-scaled-score" :style="dynamicStyle">
    {{ score }}
  </div>
</template>

<script>
export default {
  inject: ["theme"],
  props: {
    score: {
      type: [Number, String],
      required: true
    },
    size: {
      type: Number,
      default: 25
    }
  },
  computed: {
    dynamicStyle() {
      return {
        color: this.color,
        borderColor: this.color,
        width: `${this.size}px`,
        height: `${this.size}px`,
        fontSize: `${this.size / 1.625}px`,
        borderRadius: `${this.size / 2}px`
      }
    },
    color() {
      const c = parseInt(this.score)
      if (isNaN(c)) {
        return "#ffbc75"
      }

      if (c >= 60) return "#4CAF50"
      if (c >= 40) return this.theme.isDark.value ? "#c7c7c7" : "#1c1c1c"
      if (c >= 0) return "#ff002f"
      return "#ffbc75"
    }
  }
}
</script>

<style lang="scss">
.color-scaled-score {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: "currentColor";
  border-style: solid;
  border-width: 2px;
}
</style>
