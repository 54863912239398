export default function filterOutliers(someArray, upperOnly = false) {
  // Copy the values, rather than operating on references to existing values
  var values = someArray.concat()

  // Then sort
  values.sort((a, b) => a - b)

  /* Then find a generous IQR. This is generous because if (values.length / 4)
   * is not an int, then really you should average the two elements on either
   * side to find q1.
   */
  var q1 = values[Math.floor(values.length / 4)]
  // Likewise for q3.
  var q3 = values[Math.ceil(values.length * (3 / 4))]
  var iqr = q3 - q1

  // Then find min and max values
  var maxValue = q3 + iqr * 1.5
  var minValue = q1 - iqr * 1.5

  // Then filter anything beyond or beneath these values.
  var filteredValues = values.filter((x) => x <= maxValue && (upperOnly || x >= minValue))

  // Then return
  return filteredValues
}
