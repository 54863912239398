<template>
  <div class="econ-valuations">
    <div class="section-header">
      <h3>Valuations</h3>
    </div>
    <div class="d-flex mb-2">
      <BondSelect
        group-key="bondType"
        :background="highchartsPalette[0]"
        :group-colors="bondGroupColors"
        :items="bonds"
        :sort-by-fields="['text', 'year']"
        track-by-field="isin"
        display-field="text"
        :model-value="bond1"
        @update:model-value="bond1 = $event"
      ></BondSelect>
      <div class="mx-1"></div>
      <BondSelect
        group-key="bondType"
        :background="highchartsPalette[1]"
        :group-colors="bondGroupColors"
        :items="bonds"
        :sort-by-fields="['text', 'year']"
        track-by-field="isin"
        display-field="text"
        :model-value="bond2"
        @update:model-value="bond2 = $event"
      ></BondSelect>
    </div>
    <div v-if="loading" style="display: grid; place-items: center; min-height: 320px">
      <v-progress-circular :size="64" color="primary" indeterminate></v-progress-circular>
    </div>

    <div v-else-if="bonds.length === 0">
      <v-alert text type="warning">No bonds in record for {{ regionName }}</v-alert>
    </div>

    <div
      v-else
      :style="{
        display: 'grid',
        'grid-template-columns': `repeat(${cols}, minmax(0, 1fr))`,
        width: '100%',
        'grid-gap': '8px'
      }"
    >
      <div>
        <highcharts :options="chartOptions1"></highcharts>
      </div>
      <div>
        <highcharts :options="chartOptions2"></highcharts>
      </div>
      <div v-if="showRegression">
        <highcharts :options="chartOptions3"></highcharts>
      </div>
      <div v-if="showUSSwapCurve">
        <highcharts :options="chartOptions4"></highcharts>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy, maxBy, isNumber, isNil, isNaN } from "lodash-es"
import { linear } from "regression"
import Chart, { Highcharts } from "@wbim/highcharts-themed"
import GroupedSingleSelect from "@/components/common/GroupedSingleSelect.vue"
import { loadDefaultBonds, updateDefaultBonds } from "../../apis/corp"
import { fmtNum } from "@/utils/vmMethods.js"
import selectTreasureYieldCurveYear from "@/utils/selectTreasureYieldCurveYear"

const xAxis = { type: "datetime", crosshair: true, dateTimeLabelFormats: { month: "%b %Y" } }
const isValid = (d) => isNumber(d) && !isNil(d) && !isNaN(d)

export default {
  components: {
    highcharts: Chart,
    BondSelect: GroupedSingleSelect
  },
  inject: ["apiRepo", "theme", "sovereignUniverse"],
  props: {
    region: { type: String, required: true },
    //issuer is optional to provide default bond selection
    issuer: { type: String, required: false },
    showAllCorpBonds: { type: Boolean, default: false },
    showUSSwapCurve: { type: Boolean, default: true },
    showRegression: { type: Boolean, default: true }
  },
  data() {
    return {
      loading: true,
      tsyBonds: [],
      bonds: [],
      bond1Suggestions: null,
      bond2Suggestions: null,
      bond1: { text: "", value: null },
      bond2: { text: "", value: null },
      bond1Data: [],
      bond2Data: [],
      tsyBond1Data: [],
      tsyBond2Data: []
    }
  },
  computed: {
    cols() {
      switch (this.$vuetify.display.name) {
        case "lg":
          return 2
        default:
          return 1
      }
    },
    regionName() {
      return this.sovereignUniverse.fromAlpha2(this.region).name
    },
    highchartsPalette() {
      const theme = Highcharts.getOptions()
      return theme.colors
    },
    bondGroupColors() {
      return {
        Quasi: this.highchartsPalette[3],
        Corporate: this.highchartsPalette[4],
        Sovereign: this.highchartsPalette[7],
        Other: this.highchartsPalette[8]
      }
    },
    bondTypeLabels() {
      return {
        qs: "Quasi",
        corp: "Corporate",
        sov: "Sovereign",
        other: "Other"
      }
    },
    chartOptions1() {
      return {
        time: {
          //Date will parse to UTC by new Date, here we use useUTC to work around day lag
          useUTC: true
        },
        title: { text: "Absolute Spread" },
        chart: { zoomType: "x" },
        xAxis,
        yAxis: { title: { text: "Spread" } },
        tooltip: {
          valueDecimals: 1,
          xDateFormat: "%Y-%m-%d",
          crosshairs: true,
          shared: true,
          pointFormat: `{series.name}: <b>{point.y}</b><br/>`
        },
        series: [
          { name: this.bond1.text, data: this.bond1Data },
          { name: this.bond2.text, data: this.bond2Data }
        ]
      }
    },

    chartOptions2() {
      return {
        time: {
          //new Data will parse to UTC, here we use useUTC to work around day lag
          useUTC: true
        },
        title: { text: "Spread Difference" },
        chart: { zoomType: "x" },
        xAxis,
        yAxis: { title: { text: "Diff" } },
        tooltip: { valueDecimals: 1, xDateFormat: "%Y-%m-%d" },
        series: [
          {
            name: "Diff",
            data: this.bond1Data.map((d) => d[0]).map((d) => [d, this.bond1Map.get(d) - this.bond2Map.get(d)])
          }
        ]
      }
    },
    chartOptions3() {
      const vm = this
      return {
        title: { text: this.bond1.text + " vs. " + this.bond2.text },
        chart: { zoomType: "x" },
        xAxis: { type: "category", title: { text: this.bond1.text } },
        yAxis: { title: { text: this.bond2.text } },
        tooltip: {
          valueDecimals: 1,
          formatter() {
            return [`${vm.bond1.text}:\t ${vm.fmtNum(this.x, 1)}`, `${vm.bond2.text}:\t ${vm.fmtNum(this.y, 1)}`].join(
              "<br/>"
            )
          }
        },
        series: [
          {
            type: "scatter",
            data: this.bond1Data.map((d) => d[0]).map((d) => [this.bond1Map.get(d), this.bond2Map.get(d)])
          },
          {
            type: "scatter",
            data: this.bond1Data
              .filter((d) => d[0] === this.mostRecentDay)
              .map((d) => [this.bond1Map.get(d[0]), this.bond2Map.get(d[0])]),
            color: "red",
            marker: { radius: 8 }
          },
          {
            type: "line",
            name: "regression",
            data: linear(
              sortBy(
                this.bond1Data
                  .map((d) => d[0])
                  .map((d) => [this.bond1Map.get(d), this.bond2Map.get(d)])
                  .filter((d) => isValid(d[0]) && isValid(d[1])),
                (d) => d[0]
              )
            ).points
          }
        ]
      }
    },
    chartOptions4() {
      return {
        time: {
          //new Data will parse to UTC, here we use useUTC to work around day lag
          useUTC: true
        },
        title: { text: "US Swap Curve" },
        chart: { zoomType: "x" },
        xAxis,
        yAxis: { title: { text: "Swap Rate" } },
        tooltip: {
          shared: true,
          valueDecimals: 2,
          xDateFormat: "%Y-%m-%d",
          pointFormat: `{series.name}: <b>{point.y}</b><br/>`
        },
        legend: { enabled: true },
        series: [
          { name: this.bond1ComparableTsyBond, data: this.tsyBond1Data },
          { name: this.bond2ComparableTsyBond, data: this.tsyBond2Data }
        ]
      }
    },
    mostRecentDay() {
      const days = this.bond1Data.map((d) => d[0])
      return days[days.length - 1]
    },
    bond1Map() {
      return new Map(this.bond1Data)
    },
    bond2Map() {
      return new Map(this.bond2Data)
    },
    bond1ComparableTsyBond() {
      return this.getBondCompareTreasureCurve(this.bond1)
    },
    bond2ComparableTsyBond() {
      return this.getBondCompareTreasureCurve(this.bond2)
    }
  },
  async mounted() {
    const bondsResult = await Promise.all([this.loadBonds(this.regionName), this.loadSwaps()])
    this.bonds = bondsResult[0].filter((d) => !!d.bondType)
    this.tsyBonds = bondsResult[1]
    this.loading = false
    if (this.bonds.length === 0) return

    if (this.issuer) {
      const bonds = await loadDefaultBonds(this.apiRepo, this.issuer)
      this.bond1 = this.bonds.find((d) => d.value === bonds[0]) ?? this.bonds[0]
      this.bond2 = this.bonds.find((d) => d.value === bonds[1]) ?? this.bonds[1] ?? this.bonds[0]
    } else {
      this.bond1 = this.bonds[0]
      this.bond2 = this.bonds[1] ?? this.bonds[0]
    }

    this.$watch(
      "bond1",
      async () => {
        if (this.bond1 === null) return
        this.bond1Data = await this.loadBondTs(this.bond1.value)
        if (this.issuer) {
          await updateDefaultBonds(this.apiRepo, this.issuer, this.bond1.value)
        }
      },
      {
        immediate: true
      }
    )
    this.$watch(
      "bond2",
      async () => {
        if (this.bond2 === null) return
        this.bond2Data = await this.loadBondTs(this.bond2.value)
        if (this.issuer) {
          await updateDefaultBonds(this.apiRepo, this.issuer, this.bond1.value, this.bond2.value)
        }
      },
      {
        immediate: true
      }
    )
    this.$watch(
      "bond1ComparableTsyBond",
      async (v) => {
        if (v === null || v === undefined) return
        this.tsyBond1Data = await this.loadSwap(v)
      },
      {
        immediate: true
      }
    )
    this.$watch(
      "bond2ComparableTsyBond",
      async (v) => {
        if (v === null || v === undefined) return
        this.tsyBond2Data = await this.loadSwap(v)
      },
      {
        immediate: true
      }
    )
  },
  methods: {
    fmtNum,
    getBondCompareTreasureCurve(bond) {
      if (bond === null) return null
      if (bond.year === "Perp") {
        return maxBy(this.tsyBonds, (t) => t.year)
      }

      const year = parseInt(bond.year)
      const tsyYears = this.tsyBonds.map((d) => d.year)

      if (isNaN(year)) return null
      return selectTreasureYieldCurveYear(tsyYears, year)
    },
    dropdownOpen(id) {
      const wrapper = this.$refs[id].$el.querySelector(".multiselect__content-wrapper")
      wrapper.addEventListener("mousewheel", (e) => e.stopPropagation())
    },
    fmtBondName(bond) {
      if (!bond) return ""
      return String(bond.instrument ?? bond.bondName).replace(/\d\d\/\d\d\/(\d\d)/, "$1")
    },
    async loadSwaps() {
      const api = await this.apiRepo.makeServiceAPIClient()
      const { data, status } = await api.get(`/api/yearsOfSwap`)
      if (status === 200) {
        return data
      } else {
        return []
      }
    },
    async loadSwap(year) {
      const api = await this.apiRepo.makeServiceAPIClient()
      const { data, status } = await api.get(`/api/swapseries/${year}`)
      if (status === 200) {
        return data.map((d) => [Date.parse(d.day), !isValid(d.rate) ? NaN : d.rate])
      } else {
        return []
      }
    },
    waitFor(asyncFunc, millionseconds) {
      return new Promise((resolve) => {
        setTimeout(async () => {
          resolve(await asyncFunc())
        }, millionseconds)
      })
    },
    async loadBonds(regionName) {
      const api = await this.apiRepo.makeServiceAPIClient()
      const url = this.showAllCorpBonds ? "/api/corpbonds/ALL" : `/api/bond/${regionName}`
      let resp = await api.get(url)
      if (resp.status !== 200) {
        resp = await this.waitFor(() => api.get(url), 1000)
        if (resp.status !== 200) {
          return []
        }
      }
      const bonds = resp.data.map((d) => {
        return {
          isin: d.isin,
          text: this.fmtBondName(d),
          value: d.isin,
          year: d.year,
          bondType: this.bondTypeLabels[d.bondType ?? "other"],
          color: this.bondGroupColors[d.bondType ?? "other"]
        }
      })
      return bonds
    },
    async loadBondTs(isin) {
      const api = await this.apiRepo.makeServiceAPIClient()
      const { data, status } = await api.get(`/api/zspread/${isin}`)
      if (status !== 200) return []
      const parsed = sortBy(
        data.map((d) => [Date.parse(d.day), !isValid(d.zSpread) ? NaN : d.zSpread]),
        (d) => d[0]
      )
      return parsed
    }
  }
}
</script>
