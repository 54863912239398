<template>
  <SimpleQuillEditor
    ref="editor"
    :title="title"
    :scope-path="scopePath"
    :show-byline="true"
    :tile-view="tileView"
    :content="content"
    :author="author"
    @save="save"
  ></SimpleQuillEditor>
</template>

<script setup>
import { ref, inject, onMounted } from "vue"
import SimpleQuillEditor from "@/components/common/SimpleQuillEditor.vue"
import { loadGitDoc, saveGitDoc } from "@/apis/git.js"

const apiRepo = inject("apiRepo")

const props = defineProps({
  title: { type: String },
  scopePath: { type: String, required: true },
  tileView: { type: Boolean, default: false }
})
const editor = ref(null)
const content = ref(null)
const author = ref(null)

onMounted(async () => {
  const doc = await loadGitDoc(apiRepo, props.scopePath)
  content.value = doc.content
  author.value = doc.author
})

async function save(newContent) {
  const { status } = await saveGitDoc(apiRepo, props.scopePath, JSON.stringify(newContent, null, 2))
  content.value = newContent
  editor.value.showSnack(status === 201 ? "success" : "error")
}
</script>
