import { keyBy } from "lodash-es"

const l2score = (l) => {
  switch (l) {
    case "High":
      return 5
    case "Medium":
      return 2.5
    case "Low":
      return 0
    default:
      throw new Error("invalid")
  }
}
const pillarConverge = (pillar) => {
  switch (pillar) {
    case "Social Internal":
    case "Social External":
      return "Social"
    default:
      return pillar
  }
}

export const pillarOrders = ["Environmental", "Social", "Governance"]
const getFactorMateriality = (f) => l2score(f.sustainability_impact) + l2score(f.business_impact)
const getGroupFactorMateriality = (factors, group) =>
  factors.filter((d) => d.convergedPillar === group).reduce((iter, cur) => iter + cur.factorMateriality, 0)

function calcTotalMat(factors) {
  return factors.reduce((iter, cur) => (iter += cur.factorMateriality * cur.factorWeight), 0)
}

export function calcGroupTotalMat(factors, group) {
  return factors
    .filter((d) => d.convergedPillar === group)
    .reduce((iter, cur) => (iter += cur.factorMateriality * cur.factorWeight), 0)
}

export function calcGroupTotalWgt(factors, group) {
  return calcGroupTotalMat(factors, group) / calcTotalMat(factors)
}

export function calcGroupAvgScore(factors, group, converged = true) {
  return factors
    .filter((d) => (converged ? d.convergedPillar === group : d.pillar === group))
    .reduce((iter, cur) => iter + cur.score * cur.factorWeight, 0)
}

export function validScores(factors) {
  return (
    factors.length > 0 &&
    factors.every((d) => typeof d.score === "number") &&
    factors.reduce((iter, cur) => iter + cur.score, 0) > 0
  )
}

export function calcESGScore(factors) {
  if (!validScores(factors)) return 0

  return [...new Set(factors.map((d) => d.convergedPillar))].reduce((iter, group) => {
    const groupWeight = calcGroupTotalWgt(factors, group)
    const groupTotalScore = factors
      .filter((factor) => factor.convergedPillar === group)
      .reduce((iter, factor) => iter + factor.score * factor.factorWeight, 0)
    return iter + groupWeight * groupTotalScore
  }, 0)
}

export async function loadESG(api, issuer) {
  const { data: infoData } = await api.get(`/api/corp_issuers/${issuer}`)
  const sector = infoData?.emd_sector
  if (!sector) {
    throw new Error(`no valid sector is assigned to issuer ${issuer}`)
  }

  const [impactData, scoreData] = await Promise.all([
    api.get(`/api/corp_esg_factor?emd_sector=${encodeURIComponent(sector)}`),
    api.get(`/api/corp_crm_models/${issuer}?path=esg`)
  ]).then(([response1, response2]) => [response1.data, response2.data])

  const genKey = (d) => ["pillar|", d.pillar, "name|", d.name].join("_")
  const impactDict = keyBy(impactData, genKey)
  const factors = (scoreData?.details ?? impactData.map((d) => ({ ...d, score: 0 })))
    .map((d) => ({ ...d, key: genKey(d) }))
    .map((d) => {
      const { business_impact, sustainability_impact } = impactDict[d.key]
      return { ...d, business_impact, convergedPillar: pillarConverge(d.pillar), sustainability_impact }
    })
  return { ...scoreData, details: getFactorsWithComputed(factors) }
}

//used to calculate or recalcuate the computed data
export function getFactorsWithComputed(factors) {
  if (!Array.isArray(factors)) return []
  return factors
    .map((e) => ({
      ...e,
      pillarOrder: pillarOrders.indexOf(e.convergedPillar),
      factorMateriality: getFactorMateriality(e)
    }))
    .map((f, _i, arr) => {
      const groupMateriality = getGroupFactorMateriality(arr, f.convergedPillar)
      const factorWeight = f.factorMateriality / groupMateriality
      return { ...f, factorWeight }
    })
}
export function calcPillarMatWgtSummary(factors) {
  return [...new Set(factors.map((d) => d.convergedPillar))].map((convergedPillar) => {
    return {
      convergedPillar,
      materiality: calcGroupTotalMat(factors, convergedPillar),
      weight: calcGroupTotalWgt(factors, convergedPillar)
    }
  })
}

export function calcScoreSummary(factors, converged = true) {
  if (converged) {
    return {
      total: calcESGScore(factors),
      environmental: calcGroupAvgScore(factors, "Environmental", true),
      social: calcGroupAvgScore(factors, "Social", true),
      governance: calcGroupAvgScore(factors, "Governance", true)
    }
  } else {
    return {
      total: calcESGScore(factors),
      environmental: calcGroupAvgScore(factors, "Environmental", false),
      social_internal: calcGroupAvgScore(factors, "Social Internal", false),
      social_external: calcGroupAvgScore(factors, "Social External", false),
      governance: calcGroupAvgScore(factors, "Governance", false)
    }
  }
}
