export async function loadGitDoc(apiRepo, path, params = {}) {
  const config = {
    headers: { accept: "*/*" },
    params: {
      scopePath: path,
      $format: "json",
      latestProcessedChange: true
    }
  }

  try {
    const apiClient = await apiRepo.makeGitAPIClient()
    const { data, status } = await apiClient.get("/items", config)
    if (status === 404) {
      return { data: null, status }
    }

    //there is only one file in one commit always
    const { objectId: blobId, latestProcessedChange } = data.value[0]
    const author = latestProcessedChange.author

    const docResponse = await apiClient.get(`/blobs/${blobId}`, {
      headers: { accept: "*/*" }, //needed to return the content
      responseType: "json",
      ...params
    })
    const content = docResponse.data
    return { author, content }
  } catch (e) {
    throw new Error(`failed to get git doc for path ${path}`)
  }
}

export async function saveGitDoc(apiRepo, path, content, branch = "master") {
  const apiClient = await apiRepo.makeGitAPIClient()
  const refHead = await apiClient.get(`/refs?$top=1&filterContains=heads/${branch}&format=json`)
  if (!refHead || refHead.status !== 200) {
    throw new Error("unable to get latest document ref")
  }

  //latest commit id to update from.
  const oldObjectId = refHead.data.value[0].objectId
  const config = {
    responseType: "text",
    headers: { accept: "*/*" },
    params: {
      scopePath: path,
      // "versionDescriptor.version": branch,
      latestProcessedChange: true,
      $format: "json"
    }
  }
  const { status } = await apiClient.get("/items", config)
  const changeType = status === 404 ? "add" : "edit"

  const pushPayload = {
    refUpdates: [{ name: `refs/heads/${branch}`, oldObjectId }],
    commits: [
      {
        comment: `${changeType}  ${path}`,
        changes: [
          {
            changeType,
            item: { path },
            newContent: { content, contentType: "rawtext" }
          }
        ]
      }
    ]
  }

  const response = await apiClient.post("/pushes", pushPayload)
  return response
}
