<template>
  <div v-if="infoLoaded">
    <div v-show="initiated">
      <div>
        <CorpOpportunitySet
          v-if="maximized"
          :maximized="maximized"
          :issuer="issuer"
          @toggle-maximized="maximized = !maximized"
        ></CorpOpportunitySet>
      </div>
      <div v-show="!maximized" class="section-grid" :style="gridStyle">
        <div>
          <CorpIssuerDesc :issuer="issuer"></CorpIssuerDesc>
        </div>
        <div>
          <CorpCreditRiskModel :issuer="issuer"></CorpCreditRiskModel>
        </div>
        <div>
          <CorpESGSummary :issuer="issuer"></CorpESGSummary>
        </div>
        <div>
          <CorpOpportunitySet
            v-if="!maximized"
            :maximized="maximized"
            :issuer="issuer"
            @toggle-maximized="maximized = !maximized"
          ></CorpOpportunitySet>
        </div>
        <div>
          <CorpHistoricalSpread :issuer="issuer"></CorpHistoricalSpread>
        </div>
        <div>
          <CorpCreditUpdate :issuer="issuer"></CorpCreditUpdate>
        </div>
      </div>
    </div>
    <div v-if="!initiated">
      <CorpCreditUpdate style="max-width: 800px" :expanded-view="true" :issuer="issuer"></CorpCreditUpdate>
    </div>
  </div>
</template>

<script>
import CorpIssuerDesc from "@/components/CorpIssuerDescription.vue"
import CorpCreditRiskModel from "@/components/CorpCreditRiskModel.vue"
import CorpCreditUpdate from "@/components/CorpCreditUpdate.vue"
import CorpOpportunitySet from "@/components/CorpOpportunitySet.vue"
import CorpHistoricalSpread from "@/components/CorpHistoricalSpread.vue"
import CorpESGSummary from "@/components/CorpESGSummary.vue"
import { loadIssuerInfo } from "@/apis/corp.js"

export default {
  components: {
    CorpIssuerDesc,
    CorpCreditRiskModel,
    CorpCreditUpdate,
    CorpOpportunitySet,
    CorpHistoricalSpread,
    CorpESGSummary
  },
  inject: ["apiRepo"],
  props: {
    issuer: { type: String, required: true },
    showExtended: { type: Boolean, default: false }
  },
  data() {
    return {
      maximized: false,
      infoLoaded: false,
      initiated: false
    }
  },
  computed: {
    gridStyle() {
      const cols =
        (this.$vuetify.display.smAndDown ? 1 : this.$vuetify.display.lgAndUp ? 3 : 2) - (this.showExtended ? 1 : 0)
      return {
        display: "grid",
        "grid-template-columns": `repeat(${cols}, minmax(0, 1fr))`,
        "align-content": "start",
        "column-gap": "8px",
        "grid-auto-flow": "dense",
        "max-width": "1880px"
      }
    }
  },
  async mounted() {
    const info = await loadIssuerInfo(this.apiRepo, this.issuer)
    this.initiated = info.model_initiated
    this.infoLoaded = true
  }
}
</script>
