// From https://stackoverflow.com/questions/60884426/how-to-check-for-null-value-inline-and-throw-an-error-in-typescript
//
// Helper function to easily throw on null and undefined, like this:
//
//   const myString = nullableVariable ?? throwExpression("nullableVariable is null or undefined")
//
// ...at least until this proposal is approved: https://github.com/tc39/proposal-throw-expressions
export default function throwExpression(errorMessage: string): never {
  throw new Error(errorMessage)
}
