<template>
  <div>
    <div class="section-header">
      <h3>ESG</h3>
      <div class="text-overline mx-4">
        <ColorScaledScore :score="fmtNum(esgScore, 0)" />
      </div>
    </div>
    <div v-if="loading" class="content-card" style="height: 360px">
      <div style="display: grid; min-height: 320px; align-items: center; justify-content: center">
        <v-progress-circular :size="48" color="primary" indeterminate></v-progress-circular>
      </div>
    </div>
    <div v-else-if="!validScores" class="content-card" style="height: 360px">
      <v-alert density="compact" variant="outlined" type="info">
        ESG model is empty. Click
        <v-btn size="x-small" variant="text" :to="{ name: 'corp-esg', params: { issuer } }">Here</v-btn>
        to create
      </v-alert>
    </div>
    <highcharts v-else :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import Chart from "@wbim/highcharts-themed"
import { calcESGScore, loadESG, calcScoreSummary, validScores } from "@/utils/esgScorePreprocess.js"
import ColorScaledScore from "./common/ColorScaledScore.vue"
import { fmtNum } from "@/utils/vmMethods.js"

export default {
  components: { highcharts: Chart, ColorScaledScore },
  inject: ["apiRepo", "theme"],
  props: { issuer: { type: String, required: true } },
  data() {
    return {
      factors: [],
      loading: true,
      catagoryColors: ["#008566", "#8196d9", "#9a9b9d"]
    }
  },
  computed: {
    categories() {
      return [...new Set(this.factors.map((d) => d.convergedPillar))]
    },
    validScores() {
      return validScores(this.factors)
    },
    esgScore() {
      if (this.factors.length === 0) return 0
      return calcESGScore(this.factors)
    },
    chartOptions() {
      const vm = this
      if (!Array.isArray(this.factors) || this.factors.length === 0) return {}

      const scores = calcScoreSummary(this.factors, true)

      const summary = [
        {
          name: "Environmental",
          y: scores.environmental,
          color: vm.catagoryColors[0],
          drilldown: "Environmental"
        },
        {
          name: "Social",
          y: scores.social,
          color: vm.catagoryColors[1],
          drilldown: "Social"
        },
        {
          name: "Governance",
          y: scores.governance,
          color: vm.catagoryColors[3],
          drilldown: "Governance"
        }
      ]
      return {
        chart: { type: "column" },
        legend: { enabled: false },
        xAxis: { type: "category" },
        yAxis: { title: { text: "Score" }, max: 100 },
        tooltip: { enabled: false },
        plotOptions: {
          series: {
            borderWidth: 0,
            dataLabels: {
              enabled: true,
              style: {
                color: "#ccc",
                fontSize: "16px"
              },
              format: "{y:.0f}"
            }
          }
        },
        series: [{ name: "summary", data: summary }],
        drilldown: {
          series: vm.categories.map((group) => ({
            id: group,
            data: vm.factors.filter((d) => d.convergedPillar === group).map((d) => [d.name, d.score])
          }))
        }
      }
    }
  },
  watch: {
    $route(to, from) {
      if (from.name === "corp-esg") {
        this.load()
      }
    }
  },

  async mounted() {
    this.load()
  },

  methods: {
    fmtNum,
    async load() {
      const api = await this.apiRepo.makeServiceAPIClient()
      const esgData = await loadESG(api, this.issuer)
      this.factors = esgData.details
      this.loading = false
    }
  }
}
</script>
