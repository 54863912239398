<template>
  <div class="econ-technicals">
    <div class="section-header">
      <h3>Technicals</h3>
    </div>
    <div>
      <UserContentEditor
        title="Summary"
        :show-byline="false"
        class="content-card pt-2"
        :scope-path="`/doc/${region}/technical-summary.json`"
      />
    </div>
    <div v-if="false" class="my-2">
      <highcharts :options="chartOptionsGlobal"></highcharts>
    </div>
    <div class="my-2">
      <highcharts :options="chartOptionsCountry"></highcharts>
    </div>
    <div class="my-2">
      <highcharts :options="chartOptionsSurvey"></highcharts>
    </div>
  </div>
</template>

<script>
import { merge, cloneDeep } from "lodash-es"
import Chart from "@wbim/highcharts-themed"
import UserContentEditor from "@/components/common/UserContentEditor.vue"

export default {
  components: {
    UserContentEditor,
    highcharts: Chart
  },
  inject: ["apiRepo", "theme", "sovereignUniverse"],
  props: {
    region: { type: String }
  },
  data() {
    return {
      globalCF: [],
      countryCF: [],
      investorSurvey: [],
      updateDate: [],
      updateDateSurvey: [],
      chartOptionsBase: {
        chart: { type: "column" },
        xAxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
        },
        yAxis: {
          title: { text: "Payments (100M)" },
          min: 0,
          stackLabels: {
            enabled: true,
            format: "{total:.1f}"
          }
        },
        plotOptions: {
          column: {
            stacking: "normal",
            hover: { enabled: true },
            dataLabels: {
              enabled: true,
              format: "{y:.1f}"
            }
          },
          series: {
            dataLabels: {
              enabled: true,
              format: "{y:.1f}"
            }
          }
        },
        legend: { enabled: true },
        tooltip: { valueDecimals: 2 }
      }
    }
  },
  computed: {
    code3() {
      return this.sovereignUniverse.fromAlpha2(this.region)?.isoAlpha3
    },

    name() {
      return this.sovereignUniverse.fromAlpha2(this.region)?.name
    },

    chartOptionsGlobal() {
      return merge(cloneDeep(this.chartOptionsBase), {
        title: { text: "EM HC Sov Cashflows" },
        credits: {
          enabled: true,
          text: `Data last update as of ${this.updateDate[0]}`,
          href: "###"
        },
        series: [
          { name: "Interest", data: this.globalCF.map((d) => d.interest / 1e8) },
          { name: "Principal", data: this.globalCF.map((d) => d.Principal / 1e8) }
        ]
      })
    },

    chartOptionsCountry() {
      const vm = this
      return merge(cloneDeep(this.chartOptionsBase), {
        title: { text: `${vm.name} Sov Cashflows` },
        credits: {
          enabled: true,
          text: `Data last update as of ${this.updateDate[0]}`,
          href: "###"
        },
        series: [
          { name: "Interest", data: this.countryCF.map((d) => d.interest / 1e8) },
          { name: "Principal", data: this.countryCF.map((d) => d.Principal / 1e8) }
        ]
      })
    },

    chartOptionsSurvey() {
      const vm = this
      const baseConfig = {
        chart: { type: "column" },
        legend: { enabled: true },
        title: { text: `${vm.name} Investor Survey` },
        credits: {
          enabled: true,
          text: `Data last update as of ${this.updateDateSurvey[0]}`,
          href: "###"
        },
        xAxis: { categories: ["Over Weight", "Neutral", "Under Weight"] },
        yAxis: { title: { text: "Response(%)" } },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              y: 20,
              formatter() {
                return this.y.toFixed(0)
              }
            },
            hover: { enabled: true }
          }
        }
      }

      const countrySurvey = this.investorSurvey.find((d) => d["Major Assets"] === this.code3)
      if (!countrySurvey) return baseConfig

      return {
        ...baseConfig,
        series: [
          {
            name: "Today",
            data: ["Overwt %", "Neutral %", "Underwt %"].map((k) => parseInt(countrySurvey[k]))
          },
          {
            name: "Last Month",
            data: ["Overwt %", "Neutral %", "Underwt %"].map((k) => parseInt(countrySurvey[`${k} t-1`]))
          }
        ]
      }
    }
  },

  mounted() {
    this.loadGlobalAndCountryCF()
    this.loadInvestorSurvey()
  },

  methods: {
    async loadGlobalAndCountryCF() {
      const results = await Promise.all([
        this.apiRepo.requestBlobCSV("econ-cashflow/output/all.csv"),
        this.apiRepo.requestBlobCSV(`econ-cashflow/output/${this.region}.csv`),
        this.apiRepo.requestLastUpdateInfo(`econ-cashflow/last-processed-date.txt`)
      ])
      this.globalCF = results[0]
      this.countryCF = results[1]
      this.updateDate = results[2]
    },

    async loadInvestorSurvey() {
      const results = await Promise.all([
        this.apiRepo.requestBlobCSV("jpm-investor-survey/output/technicals-investor-survey.csv"),
        this.apiRepo.requestLastUpdateInfo("jpm-investor-survey/last-processed-date.txt")
      ])
      this.investorSurvey = results[0]
      this.updateDateSurvey = results[1]
    }
  }
}
</script>

<style></style>
