<template>
  <div class="quick-facts">
    <CountrySummaryEvents :region="region"></CountrySummaryEvents>
    <CountrySummaryInfo :region="region"></CountrySummaryInfo>
    <CountrySummaryPositions :region="region"></CountrySummaryPositions>
    <div class="infobox-section">
      <div class="section-header">
        <h3>Additional Items</h3>
      </div>
      <div class="content-card">
        <div class="list-edit-wrap">
          <div v-if="extItems.length === 0" class="list-add-remove">
            <v-btn
              variant="outlined"
              size="small"
              @click="
                () => {
                  isEditing = true
                  extItems.push({ key: '', value: '' })
                }
              "
            >
              <v-icon start>mdi-plus</v-icon>
              Add information item
            </v-btn>
          </div>
          <div
            v-for="({ key, value }, index) in extItems"
            :key="key"
            class="list-edit-item d-flex mt-0"
            :class="`list-edit-item__${index % 2 == 0 ? 'even' : 'odd'}`"
          >
            <v-text-field
              class="list-input-box"
              style="flex: 0 0 160px"
              density="compact"
              hide-details
              variant="outlined"
              :readonly="!isEditing"
              :style="{ pointerEvents: isEditing ? 'all' : 'none', fontWeight: 'bold' }"
              :model-value="key"
              @change="extItems[index] = { key: $event.target.value, value: value }"
            ></v-text-field>
            <v-text-field
              class="list-input-box ml-1"
              style="flex: 1"
              density="compact"
              hide-details
              variant="outlined"
              :readonly="!isEditing"
              :model-value="value"
              @click="isEditing = true"
              @change="extItems[index] = { key: key, value: $event.target.value }"
            ></v-text-field>
            <div v-if="isEditing" class="list-add-remove" style="flex: 0 0 100px">
              <v-btn
                size="small"
                icon
                @click="
                  () => {
                    extItems.splice(index, 1)
                    saveExtraItems()
                  }
                "
              >
                <v-icon>mdi-minus</v-icon>
              </v-btn>
              <v-btn
                v-if="index === extItems.length - 1"
                size="small"
                icon
                @click="extItems.push({ key: '', value: '' })"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div class="mt-2">
          <v-btn v-if="!isEditing && extItems.length > 0" variant="outlined" size="small" @click="isEditing = true">
            <v-icon size="small" start>mdi-pencil</v-icon>
            Edit
          </v-btn>
          <v-btn
            v-if="isEditing && extItems.length > 0"
            variant="outlined"
            size="small"
            color="success"
            @click="saveExtraItems"
          >
            <v-icon size="small" start>mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-btn
            v-if="isEditing && extItems.length > 0"
            class="ml-2"
            variant="text"
            size="small"
            color="warning"
            @click="
              () => {
                isEditing = false
                extItems = extItems.filter(({ key }) => key.trim() !== '')
              }
            "
          >
            Cancel
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CountrySummaryEvents from "@/components/CountrySummaryEvents.vue"
import CountrySummaryInfo from "@/components/CountrySummaryInfo.vue"
import CountrySummaryPositions from "@/components/CountrySummaryPositions.vue"

export default {
  components: {
    CountrySummaryEvents,
    CountrySummaryInfo,
    CountrySummaryPositions
  },
  inject: ["apiRepo", "sovereignUniverse"],
  props: {
    region: { type: String, required: true }
  },
  data() {
    return {
      isEditing: false,
      showMyGlobe: false,
      flagUrl: "",
      mapUrl: "",
      infoHtml: "",
      extItems: [
        { key: "Election date", value: "Input here" },
        { key: "Currency regime", value: "Input here" }
      ]
    }
  },
  computed: {
    scopePath() {
      return `/doc/${this.region}/extra-info-items.json`
    },
    regionName() {
      return this.sovereignUniverse.fromAlpha2(this.region)?.name ?? ""
    }
  },

  async mounted() {
    this.getAdditionalInfoItems()
  },
  methods: {
    async saveExtraItems() {
      const gitApi = await this.apiRepo.makeGitAPIClient()
      const { data: masterRef } = await gitApi.get("/refs?$top=1&filterContains=master&format=json")
      const oldObjectId = masterRef.value[0].objectId

      const scopePath = this.scopePath
      const config = {
        responseType: "text",
        headers: { accept: "*/*" },
        params: { scopePath, latestProcessedChange: true, format: "json" }
      }
      const { status } = await gitApi.get("/items", config)
      const changeType = status === 404 ? "add" : "edit"

      const pushPayload = {
        refUpdates: [{ name: "refs/heads/master", oldObjectId }],
        commits: [
          {
            comment: `${changeType}  ${scopePath}`,
            changes: [
              {
                changeType,
                item: { path: scopePath },
                newContent: {
                  content: JSON.stringify(this.extItems, null, 4),
                  contentType: "rawtext"
                }
              }
            ]
          }
        ]
      }
      const response = await gitApi.post("/pushes", pushPayload)
      if (response.status === 201) {
        this.isEditing = false
      }
    },
    async getAdditionalInfoItems() {
      const gitApi = await this.apiRepo.makeGitAPIClient()
      const config = {
        responseType: "text",
        headers: { accept: "*/*" },
        params: {
          scopePath: this.scopePath,
          latestProcessedChange: true,
          format: "json"
        }
      }
      const { data, status } = await gitApi.get("/items", config)
      if (status === 404 || data.length === 0) {
        return
      }
      this.extItems = data
    }
  }
}
</script>

<style lang="scss">
.infobox-section {
  .v-input {
    .v-theme--light & {
      color: #000;
    }
    .v-theme--dark & {
      color: #c1c1c1;
    }
  }

  .v-input.list-input-box {
    padding: 2px;
    .v-input__control > .v-input__slot {
      margin-left: -2px;
      padding: 0 !important;
      min-height: 24px !important;
      font-size: 13px;
      input {
        padding: 2px 4px;
        .v-theme--light & {
          color: #000 !important;
        }
        .v-theme--dark & {
          color: #c1c1c1 !important;
        }
      }
    }
  }
  .v-input--is-readonly {
    fieldset {
      border-color: transparent;
    }
    input {
      font-size: 13px;
    }
    .v-text-field__slot:focus {
      outline: initial;
    }
  }
}
</style>
