import log from "loglevel"
import axios from "axios"
import Papa from "papaparse"
import { merge } from "lodash-es"
import { useMsal } from "@wbim/msal-compositional"

function interceptAxiosInstance(instance, configOverride = {}) {
  instance.interceptors.request.use(
    function (config) {
      return merge(config || {}, configOverride)
    },
    function (error) {
      if (error.request) {
        // senario like network disconected
        log.error(error.request)
      } else {
        // Something happened in setting up the request and triggered an Error
        log.error("Error", error.message)
      }
      log.error(error.config)
    }
  )

  instance.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response && error.response.status !== 404) {
        log.info(error.response)
      }
      return error.response
    }
  )
}
function createConfiguredAxiosInstance(config, requestConfigOverride = {}) {
  const instance = axios.create(config)
  interceptAxiosInstance(instance, requestConfigOverride)
  return instance
}

export class APIRepository {
  #getAccessToken

  constructor(appConfig) {
    //need to put in instance constructor to allow the initialization
    const msal = useMsal()
    this.#getAccessToken = msal.getAccessToken
    this.baseUrls = {
      git: `https://dev.azure.com/wbim/${appConfig.VITE_ProjId}/_apis/git/repositories/${appConfig.VITE_RepoId}`,
      app: appConfig.VITE_ServiceBase,
      summit: appConfig.VITE_SummitBase,
      emd: appConfig.VITE_EMDBase
    }

    this.scopes = {
      // msal 2.0 accessing 1.0 resources
      azureDevOps: { scopes: ["499b84ac-1321-427f-aa17-267ca6975798/user_impersonation"] },
      msGraph: {
        scopes: [
          "https://graph.microsoft.com/User.Read",
          "https://graph.microsoft.com/User.ReadBasic.All",
          "https://graph.microsoft.com/Directory.Read.All"
        ]
      },
      serviceScope: { scopes: [appConfig.VITE_ServiceScope] },
      summitScope: { scopes: [appConfig.VITE_SummitScope] },
      emdScope: { scopes: [appConfig.VITE_EMDScope] }
    }
  }

  async makeGenericAPIClient(serviceBase, serviceScope, extraAxiosConfig = {}) {
    const accessToken = await this.#getAccessToken(serviceScope)
    if (!accessToken) return null
    const config = {
      baseURL: serviceBase,
      headers: {
        "Cache-Control": "no-cache",
        Authorization: `Bearer ${accessToken}`
      }
    }
    const axiosConfig = merge(config, extraAxiosConfig)
    return createConfiguredAxiosInstance(axiosConfig)
  }

  async makeGitAPIClient() {
    return this.makeGenericAPIClient(this.baseUrls.git, this.scopes.azureDevOps, { params: { "api-version": "5.1" } })
  }

  makeMSGraphAPIClient() {
    const graphBase = "https://graph.microsoft.com/v1.0"
    return this.makeGenericAPIClient(graphBase, this.scopes.msGraph)
  }

  makeDremioAPIClient() {
    // Dremio API is exposed via a proxy which checks a valid WB user access token with MS Graph scope
    return this.makeMSGraphAPIClient()
  }

  makeServiceAPIClient() {
    return this.makeGenericAPIClient(this.baseUrls.app, this.scopes.serviceScope)
  }

  makeSummitAPIClient() {
    return this.makeGenericAPIClient(this.baseUrls.summit, this.scopes.summitScope, {
      headers: {
        "Cache-Control": "no-cache",
        "X-Summit-ViewType": "Global",
        versionId: "1.0.0"
      }
    })
  }

  makeEMDAPIClient() {
    return this.makeGenericAPIClient(this.baseUrls.emd, this.scopes.emdScope)
  }

  async requestBlobCSV(path, options = {}) {
    const api = await this.makeServiceAPIClient()
    const url = `/api/BlobProxy?path=${path}`
    const { data, status } = await api.get(url, { responseType: "blob" })
    if (status !== 200) return []

    return new Promise(function (resolve, reject) {
      Papa.parse(data, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete(result) {
          if (result.errors.length > 0) reject(result.errors)
          else resolve(result.data)
        },
        ...options
      })
    })
  }

  async requestLastUpdateInfo(path, options) {
    const parsed = await this.requestBlobCSV(path, { header: false, delimiter: " ", ...options })
    return parsed[0]
  }
}

export default APIRepository
