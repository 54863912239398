<template>
  <div style="width: 100%; font-size: 12px">
    <v-autocomplete
      v-model="instrument"
      v-model:search="search"
      placeholder="Type to start."
      density="compact"
      :items="completeItems"
      item-title="text0"
      item-value="value"
      no-filter
      return-object
      hide-no-data
      hide-details
      prepend-inner-icon="mdi-magnify"
      @focus="focused"
    >
      <template #item="{ props, item }">
        <v-list-item class="query-item" v-bind="props" :title="item?.raw?.text">
          <template #append>
            <span
              v-if="item.raw.type === 'country'"
              class="region-flag flag-icon"
              :class="`flag-icon-${item.raw.value.toLowerCase()}`"
            ></span>
            <span class="font-weight: bold">{{ item.raw.ticker }}</span>
          </template>
        </v-list-item>
      </template>

      <template #append>
        <v-slide-x-reverse-transition mode="out-in">
          <span
            v-if="instrument && instrument.type === 'country'"
            class="region-flag flag-icon"
            :class="`flag-icon-${instrument.value.toLowerCase()}`"
          ></span>
        </v-slide-x-reverse-transition>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { loadCorpIssuers } from "@/apis/corp.js"

export default {
  inject: ["apiRepo", "sovereignUniverse"],
  props: {
    modelValue: { type: [String, Number] }
  },
  emits: ["update:instrument"],
  data() {
    return {
      search: "",
      instrument: null,
      completeItems: [],
      regions: [],
      countryData: [],
      corpData: []
    }
  },

  computed: {
    items() {
      return [...this.corpData, ...this.regions]
    }
  },
  watch: {
    instrument(v) {
      this.$emit("update:instrument", v)
    },
    value() {
      this.instrument = this.items.find((d) => d.value === this.value)
    },
    search(v) {
      if (!v) {
        this.completeItems = this.regions
      } else {
        this.completeItems = [...this.corpData, ...this.regions]
          .map((d) => {
            const vv = v.toUpperCase()
            const text = d.text.toUpperCase()
            const value = d.value.toUpperCase()
            //only meaningful to corp
            const ticker = d?.raw?.ticker ?? ""

            let rank = 0
            if (value === vv || ticker === vv || text === vv) {
              rank = 100
            } else if (value.startsWith(vv) || ticker.startsWith(vv) || text.startsWith(vv)) {
              rank = 10
            } else if (text.includes(vv) || ticker.includes(vv)) {
              rank = 1
            }
            return { ...d, rank }
          })
          .filter((d) => d.rank > 0)
          .sort((a, b) => b.rank - a.rank)
      }
    }
  },

  async beforeMount() {
    this.regions = this.sovereignUniverse.map((r) => ({
      type: "country",
      text0: r.name,
      text: r.name + " - " + r.isoAlpha2,
      value: r.isoAlpha2 ?? ""
    }))

    const issuerDict = await loadCorpIssuers(this.apiRepo, true)
    this.corpData = Object.values(issuerDict)
      .map((d) => {
        return {
          type: "corp",
          text0: d.issuer_name ?? "",
          text: (d.issuer_name ?? "") + " - " + d.ticker,
          value: String(d.issuer_id) ?? "",
          ticker: d.ticker
        }
      })
      .filter((d) => d.value) //TODO: here temporarily filter out those without IMDW IssuerID
  },

  methods: {
    focused() {
      this.instrument = null
      this.search = ""
    }
  }
}
</script>

<style scoped>
.region-flag {
  width: 40px;
  height: 28px;
  pointer-events: none;
}

:deep(.v-theme--dark .v-list-item__mask) {
  color: #3077c9;
  background: #fff0;
  font-weight: 600;
}
.query-item {
  font-size: 14px;
}
.query-item :deep(.v-list-item-title) {
  width: 32ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
