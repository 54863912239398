<template>
  <v-progress-linear
    class="scaled-bar"
    rounded
    :model-value="value * 100"
    :height="height"
    :color="color"
    :style="{ borderRadius: `${height / 2}px` }"
  >
    <span class="text-body-2 numeric--condensed">
      {{ fmtNum(value * 10, 1) }}
    </span>
  </v-progress-linear>
</template>

<script>
import { fmtNum } from "@/utils/vmMethods.js"

export default {
  props: {
    height: { type: Number, default: 24 },
    value: { type: Number, default: 0 },
    color: { type: String, default: "teal" }
  },
  methods: {
    fmtNum
  }
}
</script>

<style lang="scss">
.scaled-bar {
  .numeric--condensed {
    font-family: "Roboto Condensed" !important;
  }
}
</style>
