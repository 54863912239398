<template>
  <v-theme-provider with-background :theme="isDark ? 'dark' : 'light'">
    <v-app class="myapp-root">
      <v-app-bar theme="dark" density="compact" style="z-index: 999">
        <v-toolbar-title style="flex: 150px 0">
          <router-link :to="{ name: 'home' }" style="display: flex">
            <img src="./assets/img/logo.svg" height="24" width="133" />
          </router-link>
        </v-toolbar-title>
        <TopNav></TopNav>
        <AutoComplete v-if="widescreen" v-model:instrument="currentInstrument" class="search"></AutoComplete>
        <div style="flex: 1 0 16px"></div>
        <v-switch
          density="compact"
          hide-details
          class="flex-grow-0 mx-4"
          prepend-icon="mdi-theme-light-dark"
          @update:model-value="toggleDark()"
        ></v-switch>
        <div v-if="user" class="d-flex align-center">
          <UserAvatar :key="user?.name" :name="user?.name" :size="32" />
        </div>
        <template v-if="!widescreen" #extension>
          <AutoComplete v-model:instrument="currentInstrument"></AutoComplete>
        </template>
      </v-app-bar>

      <v-main>
        <!-- use component instead of route to get rid of redundant navigation
           when failed to acquire multiple access tokens. -->
        <FatalError v-if="hasFatalError" :error="appStatus.fatalError"></FatalError>
        <template v-else>
          <router-view v-if="user" v-slot="{ Component }">
            <keep-alive :max="10">
              <TransitionPage>
                <component :is="Component" :key="keyGen" />
              </TransitionPage>
            </keep-alive>
          </router-view>
          <!-- Dismissable Error/Message -->
          <UserError v-if="hasUserError" :error="appStatus.userError" @confirm="appStatus.userError = null"></UserError>
        </template>
      </v-main>
    </v-app>
  </v-theme-provider>
  <PWAPrompt></PWAPrompt>
</template>

<script setup lang="ts">
import { ref, reactive, computed, inject, watch, onMounted } from "vue"
import { useBreakpoints, breakpointsTailwind } from "@vueuse/core"
import { useRoute, useRouter } from "vue-router"
import { useMsal } from "@wbim/msal-compositional"
import localforage from "localforage"
import PWAPrompt from "@/components/common/PWAPrompt.vue"
import UserAvatar from "@/components/common/UserAvatar.vue"
import FatalError from "@/components/common/FatalError.vue"
import UserError from "@/components/common/UserError.vue"
import AutoComplete from "@/components/common/AutoComplete.vue"
import TopNav from "./components/common/TopNav.vue"
import TransitionPage from "@/TransitionPage.vue"
import EventBus from "./utils/eventbus.js"
import "@/assets/css/main.scss"

const { isDark, toggleDark } = inject("theme") as { isDark: boolean; toggleDark: () => void }

const { instance } = useMsal()
if (!instance) {
  throw new Error("Msal instance is not initialized")
}
const user = instance.getActiveAccount()
const route = useRoute()
const router = useRouter()
const breakpoints = useBreakpoints(breakpointsTailwind)
//vueuse breakpoints API is confusing,  we can not put the greaterOrEqual in the computed
const widescreen = breakpoints.greaterOrEqual("xl")

const appStatus = reactive({
  fatalError: null, //fatal error use an error only page
  userError: null //user error use a modal
})
const currentInstrument = ref<string | null>(null)
const hasFatalError = computed(() => !!appStatus.fatalError)
const hasUserError = computed(() => !!appStatus.userError)
const keyGen = computed(() => {
  const { meta } = route
  if (typeof meta?.keyGen === "function") {
    return meta.keyGen(route)
  }
  return route.fullPath
})

onMounted(async () => {
  //eslint-disable-next-line
  EventBus.$on("fatal", (error: any) => {
    appStatus.fatalError = error
  })
  //eslint-disable-next-line
  EventBus.$on("error", (error: any) => {
    appStatus.userError = error
  })
  EventBus.$on("logout", () => {
    const accounts = instance.getAllAccounts() ?? []
    const logoutUser = accounts.length > 0 ? { account: accounts[0] } : undefined
    instance.logoutRedirect(logoutUser)
  })
})

onMounted(async () => {
  currentInstrument.value = getCurrentInstrument()
})

watch(
  () => currentInstrument.value,
  (v) => {
    //eslint-disable-next-line
    //@ts-ignore
    const id = v?.value
    if (!id) return

    localforage.setItem("last-viewed-instrument", id)
    if (/\d{1,}/.test(id)) {
      router.push({
        name: "corp-overview",
        params: { issuer: id }
      })
    } else {
      router.push({
        name: "econ-overview",
        params: { region: id }
      })
    }
  }
)

function getCurrentInstrument() {
  const { params } = route
  if (!route.name) return null

  const name = String(route.name)

  if (name.startsWith("econ") || name.startsWith("equity")) {
    return params.region as string
  } else if (/^corp-/.test(name)) {
    return params.issuer as string
  }
  return null
}
</script>

<style lang="scss">
.search {
  max-width: 520px !important;
}
</style>
